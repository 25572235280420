module.exports = [
  {
    id: "gl-draw-polygon-fill-active-fill",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#4dd0e1",
      "fill-outline-color": "#4dd0e1",
      "fill-opacity": 0.4
    }
  },
  {
    id: "gl-draw-polygon-active-outline",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 1],
      "line-width": 1
    }
  },
  {
    id: "gl-draw-line-string-active-outline",
    type: "line",
    filter: [
      "all",
      ["==", "active", "true"],
      ["==", "$type", "LineString"],
      ["==", "mode", "draw_line_string"]
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 1],
      "line-width": 1
    }
  }
  // , {
  //   "id": "gl-draw-line-string-active-fill",
  //   "type": "fill",
  //   "filter": [
  //     "all",
  //     ["==", "active", "true"],
  //     ["==", "$type", "LineString"]
  //   ],
  //   "paint": {
  //     "fill-color": "#4dd0e1",
  //     "fill-outline-color": "#4dd0e1",
  //     "fill-opacity": 0.3
  //   }
  // }
];
