import {makeAutoObservable } from 'mobx';
import mapboxStore from './mapboxStore';
import { requests } from './apiagent';
import API_URL from '../config';
import { DataLayerType, EstimateResultType, ModeType } from '../types';
import { generatePolygonDrawLayer } from './mapboxUtil'
import uiStore from './uiStore';
import estimateContextStore from './estimateContextStore';
import { DrawAction } from '../components/hooks/useDraw';

export type ErrorMessage = {
    id: number;
    msg: string;
}

export class EstimateStore{
    loading: boolean = false
    planningEstimateResult: EstimateResultType = {} as EstimateResultType
    placeImages: Array<string> = []
    estimateReturned: boolean = this.planningEstimateResult.assessment_area >= 0
    token: string = ''
    messages: Array<ErrorMessage> = []
    showContextAlert: boolean = false
    estimateDrawOpen: boolean = false
    showHideEstimateDataSet: boolean = true

    constructor() {
        makeAutoObservable(this)
    }

    // show/hide dataset yearly image layers after estimate 
    handleToggleShowHideEstimateDataSet() {
        this.showHideEstimateDataSet = this.showHideEstimateDataSet ? false : true
    }

    handleResetToggleShowHideEstimate() {
        this.showHideEstimateDataSet = false
    }

    toggleEstimateRightDraw(estimateDrawOpen: DrawAction) {
        this.estimateDrawOpen = estimateDrawOpen === DrawAction.CLOSE ? false : true
    }

    checkInput(){
        if(!estimateContextStore.mode){
            this.messages.push({ id: 1, msg: "Please select an estimate mode." })
        }
        if(estimateContextStore.dataset.length === 0){
            this.messages.push({ id: 2, msg: "Please select an indicator." })
        }
        if(estimateContextStore.mode === ModeType.planning){
            if(!estimateContextStore.planningActionType){
                this.messages.push({ id: 3, msg: "Please select a type of event or action." })

            }
        }
        // if(estimateContextStore.mode === ModeType.monitoring){
        //     if(!estimateContextStore.actionYear){
        //         this.messages.push("Please select an action year.")

        //     }
        // }
        if(mapboxStore.polygons.length === 0){
            this.messages.push({ id: 4, msg: "Please select an polygon using the area tool." })
        }
        if(this.messages.length>0){
            this.showContextAlert = true
            return true
        }
        return false
    }

    closeContextAlert = () => {
        this.showContextAlert = false
        this.messages = []
    }

    requestEstimate() {
        if (this.checkInput()) {
            return
        }

        this.toggleEstimateRightDraw(DrawAction.OPEN)

        this.loading = true
        this.planningEstimateResult = {} as EstimateResultType
        // uiStore.setRightDrawerWidth(uiStore.rightDrawerMaxWidth)
        let data = {
            "polygon": JSON.stringify(generatePolygonDrawLayer(mapboxStore.polygons[0])),
            "mode": estimateContextStore.mode,
            "start_year": estimateContextStore.startYear,
            "end_year": estimateContextStore.endYear,
            "action_year": estimateContextStore.actionYear,
            "dataset": estimateContextStore.dataset
        }

        if (estimateContextStore.mode === ModeType.planning) {
            //@ts-ignore
            data['action_type'] = estimateContextStore.planningActionType

            requests.post(API_URL.PLANNING, data=data).then(res => {
                let result = res as EstimateResultType

                if (result.datasets) {
                    result.datasets.forEach(estimate => {
                        estimate.summary.estimatedActionYear = estimateContextStore.actionYear
                        //if(estimate.name === DataLayerType.BiodiversityPersistence){
                        const keys = Object.keys(estimate.geotiff.images)
                        /*} else {
                            const keys = Object.keys(estimate.annual_averages.mean)
                        }*/
                        estimate.summary.estimatedStartYear = keys[0]
                        estimate.summary.estimatedEndYear = keys[keys.length - 1]
                    })
                }
                this.planningEstimateResult = result

            }).catch((error: any) => {
                console.error("🚀 ~ file: estimateStore.ts ~ line 115 ~ EstimateStore ~ requestEstimate ~ error", error)
            }).finally(() => this.loading = false)
        } else {
            requests.post(API_URL.MONITORING, data=data).then(res=>{
                //Monitoring
                let result = res as EstimateResultType

                if(result.datasets) {
                    result.datasets.forEach(estimate => {
                        if (estimate.name === DataLayerType.BiodiversityPersistence) {
                            //biodiversity-persistence data layer, get start and end year from annual_average.biodiversity_persistence.mean
                            estimate.summary.estimatedActionYear = estimateContextStore.actionYear
                            //@ts-ignore
                            const keys = Object.keys(estimate.annual_averages.biodiversity_persistence.mean)
                            estimate.summary.estimatedStartYear = keys[0]
                            estimate.summary.estimatedEndYear = keys[keys.length-1]

                        } else if (estimate.name === DataLayerType.HabitatCondition || estimate.name === DataLayerType.HabitatConnectivity) {
                            estimate.summary.estimatedActionYear = estimateContextStore.actionYear

                            const keys = Object.keys(estimate.annual_averages.mean)
                        

                            estimate.summary.estimatedStartYear = keys[0]
                            estimate.summary.estimatedEndYear = keys[keys.length-1]

                        } else if (estimate.name === DataLayerType.TreeHeight) {
                            estimate.summary.estimatedActionYear = estimateContextStore.actionYear

                            const keys = Object.keys(estimate.annual_averages.mean)

                            estimate.summary.estimatedStartYear = keys[0]
                            estimate.summary.estimatedEndYear = keys[keys.length-1]

                        } else if (estimate.name === DataLayerType.ThreatenedSpecies) {
                            estimate.summary.estimatedActionYear = estimateContextStore.actionYear

                            const keys = Object.keys(estimate.annual_averages.sum)
                            
                            estimate.summary.estimatedStartYear = keys[0]
                            estimate.summary.estimatedEndYear = keys[keys.length-1]
                        }

                    })
                }

                this.planningEstimateResult = result
            }).catch(error => 
                console.error("🚀 ~ file: estimateStore.ts ~ line 156 ~ EstimateStore ~ requestEstimate ~ error", error)
            ).finally(() => this.loading = false)

        }

    }
    

    getPlaceImages() {
        this.placeImages = []
        let placeImageUrlTemplate: string = 'https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&key=AIzaSyChX5_uIHlnYF0kKrVe3bIaSXzNMbDP9L4&photo_reference='
        const centerPoint = mapboxStore.getCenterOfPolygon()
        requests.get(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?radius=5000&key=AIzaSyChX5_uIHlnYF0kKrVe3bIaSXzNMbDP9L4&location=${centerPoint.lat}%2C${centerPoint.lng}`).then(res=>{
            //@ts-ignore
            res['results'][0]&&res['results'][0]['photos'].map(ele=>{
                this.placeImages.push(placeImageUrlTemplate+''+ele['photo_reference'])
            })
        })
    }

    resetPlaceImagesAndPlanningEstimateResult() {
        this.placeImages = []
        const planningEstimateResultDefault: EstimateResultType = {} as EstimateResultType
        this.planningEstimateResult = planningEstimateResultDefault
    }
}

export default new EstimateStore()