const API_URL_ROOT = process.env.REACT_APP_API_URL
console.log("From config: " + API_URL_ROOT);
console.log("From config: " + process.env.NODE_ENV)
let API_URL =  {
    ROOT_URL: API_URL_ROOT,
    PLANNING: '/planning',
    MONITORING: '/monitoring',
    AREA: '/area',
    WARM_UP_LAMBDA: '/warmup'

}
export const REACT_APP_COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID||"s5sa1qqm9m8n7cn5trhlo2i8e"

export default API_URL