import React, { MouseEvent } from "react";
import ReactMapboxGl, { ZoomControl, Layer, Source } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
// Mapbox
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl, { LngLatBounds, Map } from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { Alert } from "@material-ui/lab"
import { ReactComponent as Infomation } from "./information.svg";
import "./MapboxMap.css";
import { Polygon, LatLng, MapboxPolyCoords, DataLayerType } from "../../types";
import {
  generatePolygonDrawLayer,
  generatePolygonLayer,
  disablePolygonDrawTool,
  disablePolygonDeleteTool,
  DrawStatus, 
  PolygonStatus,
  MapHelpMessage
} from "../../stores/mapboxUtil";
import mapboxStore from "../../stores/mapboxStore";
import estimateStore from "../../stores/estimateStore";

const MapboxGeocoder = require("@mapbox/mapbox-gl-geocoder");
const isSupported = require("@mapbox/mapbox-gl-supported").supported();
const accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN as string;
const defaultTheme = require("@mapbox/mapbox-gl-draw/src/lib/theme.js");
const customStyle = require("./mapboxStyleCustom.js");
const drawStyles: Array<Object> = defaultTheme.concat(customStyle);

export const AUcenter: LatLng = { lat: -28.25, lng: 133.416667 };
export const AUbounds = new LngLatBounds([
  [80, -60],
  [190, 10]
]); // [sw,ne]
export const AUSearchBounds = [90, -50, 180, -10]; // minLon,minLat,maxLon,maxLat

const controls: Partial<{
  point: boolean;
  line_string: boolean;
  polygon: boolean;
  trash: boolean;
  combine_features: boolean;
  uncombine_features: boolean;
}> = {
  point: false,
  line_string: true,
  polygon: false,
  trash: true,
  combine_features: false,
  uncombine_features: false
};

type MapProps = {
  center: MapboxPolyCoords;
  // zoom: number;
  coords: Array<MapboxPolyCoords>;
  style: string;
  mapStyle: { height: string; width: string };
  // @ts-ignore
  onDrawCreate?: (draw: any) => void;
  // @ts-ignore
  onDrawUpdate?: (draw: any) => void;
  // @ts-ignore
  onDrawDelete?: (draw: any) => void;
  // @ts-ignore
  onDrawModeChange?: ({ features: any }) => void;
  // @ts-ignore
  onDrawSelectionChange?: (draw: any) => void;
  onLoadFile?: () => void;
  onDownloadFile?: () => void;
  setDrawControl?: (drawControl: DrawControl | null) => void;
  showInteractive: boolean;
  fitBounds?: boolean; // overrides center and zoom if specified
  fitBoundsCallback?: (map: mapboxgl.Map) => void;
  onZoomChanged?: (map: mapboxgl.Map) => void;
  helperMessage?: MapHelpMessage;
  selectedArea?: number;
  initPolygon: Polygon;
  images?: object | undefined;
  imageCoords?: Array<Array<number>> | undefined;
  datasetName?: string | undefined;
  showHideEstimateDataSetImageLayer: boolean; // this value shows/hides yearly image layers after estimate.
};

let Mapbox: any;

class Mboxmap extends React.Component<MapProps> {
  public mapRef: React.RefObject<any>;

  constructor(props: MapProps) {
    super(props);
    this.mapRef = React.createRef();
    Mapbox = ReactMapboxGl({
      accessToken,
      attributionControl: false,
      interactive: this.props.showInteractive,
      preserveDrawingBuffer: true,
    });
  }
  state = {
    helperBox: true,
    currentSelectedYear: 0,
  };

  private switchHelperBox = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    this.setState({
      helperBox: !this.state.helperBox
    });
  };

  changeSelectedYear(year: number){
    this.setState({ currentSelectedYear: year })
  }

  loadMap = (map: any) => {

    if (map) {
      // adding the map to mobX and then returning (as "mapActual") it here so we can make update to is in the store as well as here.
      const mapActual: any = mapboxStore.setMapActual(map)

      if (this.props.showInteractive) {
        // here we set the initial zoom for the map 3 being "continent" in this case Australia
        mapActual.setZoom(mapboxStore.zoom);

        // Search box
        // Refer this document to find detail of geocoding: https://docs.mapbox.com/api/search/#batch-geocoding
        const geocoder = new MapboxGeocoder({
          // zoom: [3],
          accessToken: accessToken,
          mapboxgl: ReactMapboxGl,
          bbox: AUSearchBounds,
          types: "place,locality,postcode",
        });
        mapActual.addControl(geocoder);
        // Fullscreencontrol
        // map.addControl(new mapboxgl.FullscreenControl());
        // Geolocation
        mapActual.addControl(
          new mapboxgl.GeolocateControl({
            trackUserLocation: true,
            showUserLocation: true
          })
        );
        if (this.props.initPolygon) {
          disablePolygonDrawTool(DrawStatus.Enable, PolygonStatus.Valid);
          disablePolygonDeleteTool(false);
          //@ts-ignore
          const drawcontrol = mapActual._controls.filter(c => c.modes); //just hope nothing else has a 'modes' prop
          const feture_id = drawcontrol[0].add(
            generatePolygonDrawLayer(this.props.initPolygon)
          );
          // Use changeMode method to force the draw tool change mode with polygon selected
          drawcontrol[0].changeMode("simple_select", { featureIds: feture_id });
        } else {
          // Disable delete tool at default
          disablePolygonDeleteTool(true);
        }
      } else {
        // map.interactive could only be set during initializing of map, this has been implemeted at constructor(), where Mapbox is re-initialized
        if (this.props.initPolygon.length > 0)
          mapActual.addLayer(generatePolygonLayer(this.props.initPolygon));
          // console.log('generate polygon layer called');
      }
      // Fit to polygon bounds if provided
      if (this.props.fitBounds && this.props.fitBoundsCallback) {
        this.props.fitBoundsCallback(mapActual);
      }
      
      /*if(this.props.fitBounds){
        console.log('fitBounds is true');
      } else{
        console.log('fitBounds is false');
      }*/
    }
  };

  addImageLayer = (images: object) => {
    let base64Image = ''

    if (this.state.currentSelectedYear === 0) {
      this.changeSelectedYear(Number.parseInt(Object.keys(images)[0]) || 0)
      base64Image = Object.values(images)[0]
    } else if (!Object.keys(images).includes(this.state.currentSelectedYear.toString())) {
      this.changeSelectedYear(Number.parseInt(Object.keys(images)[0]) || 0)
      base64Image = Object.values(images)[0]
    } else {
      let idx = Object.keys(images).findIndex(ele => Number.parseInt(ele) === this.state.currentSelectedYear)
      console.log("Images idx: " + idx);
      base64Image = Object.values(images)[idx]
    }

    const Image_SOURCE_OPTIONS = {
      "type": "image",
      "url": 'data:image/png;base64,'+base64Image,
      "coordinates": this.props.imageCoords
    };

    return (
      <div
        id="testLayer"
        style={{cursor: 'pointer'}}
        onMouseEnter={(map) => console.log('-- mouse enter', map)}
      >
        <Source id="source_id" tileJsonSource={Image_SOURCE_OPTIONS} />
        <Layer  id="layer_id" type='raster' sourceId="source_id"
          onMouseEnter={(map:any) => console.log('mouse enter', map)}
          onMouseLeave={(map:any) => console.log('mouse leave', map)}
          onClick={(map:any) => console.log('onclick', map)}
        />
      </div>
    )
  }

  handleShowHideImageLayerDataset = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    estimateStore.handleToggleShowHideEstimateDataSet()
  }
  
  render() {
    const { style, mapStyle, center, onZoomChanged, showInteractive, showHideEstimateDataSetImageLayer } = this.props;

    // don't show the map until it is interactive.
    if (!showInteractive) {
      return (
        <span />
      )
    }

    if (!isSupported) {
      return (
        <Alert severity="error">
          Unfortunately your browser does not support WebGL which is used for rendering the Map.
          Please try again with any modern browser.
        </Alert>
      );
    } else {
      return (
        <Mapbox
          style={style}
          center={center}
          ref={this.mapRef}
          maxBounds={AUbounds}
          onDragEnd={() => {}}
          containerStyle={mapStyle}
          onZoomEnd={onZoomChanged}
          onStyleLoad={this.loadMap}
        >
          {showInteractive && (
            <ZoomControl position="bottom-right" />
          )}

          {this.props.showInteractive && (
            <DrawControl
              // ref={this.mapRef}
              ref={(drawControl) => this.props.setDrawControl && this.props.setDrawControl(drawControl)}
              controls={controls}
              styles={drawStyles}
              userProperties={true}
              displayControlsDefault={false}
              onDrawCreate={this.props.onDrawCreate}
              onDrawUpdate={this.props.onDrawUpdate}
              onDrawDelete={this.props.onDrawDelete}
              onDrawModeChange={this.props.onDrawModeChange}
              onDrawSelectionChange={this.props.onDrawSelectionChange}
            />
          )}

          {this.props.showInteractive ? (
            <div className="MapboxMap__polygon-load-from-file">
              <button className="MapboxMap__polygon-load-from-file-button" onClick={this.props.onLoadFile}></button>
              <button className="MapboxMap__polygon-save-to-file-button" onClick={this.props.onDownloadFile}></button>
            </div>
          ) : (
            <></>
          )}

          {this.props.showInteractive && (
            this.state.helperBox ? (
              <div>
                <div
                  className={
                    this.props.helperMessage === MapHelpMessage.Init
                      ? "MapboxMap__helper-box-open MapboxMap__helper-box__init"
                      : "MapboxMap__helper-box-open"
                  }
                >
                  <span
                    className="MapboxMap__close"
                    onClick={this.switchHelperBox}
                  >
                    &#10005;
                  </span>
                  <div id="helper-area">
                    <p>{this.props.helperMessage}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="MapboxMap__helper-box-close">
                <span className="open" onClick={this.switchHelperBox}>
                  <Infomation />
                </span>
              </div>
            )
          )}
          {this.props.images && (
            <div className="MapboxMap__polygon-image-layer">
              <button
                onClick={this.handleShowHideImageLayerDataset}
                className="MapboxMap__polygon-image-layer-button"
              > 
                {showHideEstimateDataSetImageLayer ? 'Hide' : 'Show' }
              </button>
              {Object.keys(this.props.images).map(year =>
                <button
                  key={year}
                  className="MapboxMap__polygon-image-layer-button"
                  onClick={() => this.changeSelectedYear(Number.parseInt(year))}
                  disabled={Number.parseInt(year) === this.state.currentSelectedYear}
                > 
                  {year}
                </button>
              )}
            </div>
          )}

          {showHideEstimateDataSetImageLayer && this.props.images && (this.addImageLayer(this.props.images))}

          {(this.props.datasetName === DataLayerType.HabitatCondition
            || this.props.datasetName === DataLayerType.HabitatConnectivity) && this.props.images && (
            <div className="MapboxMap__image-legend">
              <div className="MapboxMap__image-legend-label">
                <div> 100%</div>
                <div> 0%</div>
              </div>
              <div className="MapboxMap__image-legend-color"></div>
            </div>
          )}

          {this.props.datasetName==="tree-height" && this.props.images && (
            <div className="MapboxMap__image-legend">
              <div className="MapboxMap__image-legend-label">
                <div> 30m</div>
                <div> 0m</div>
              </div>
              <div className="MapboxMap__image-legend-color-tree-height"></div>
            </div>
          )}

          {this.props.datasetName==="biodiversity-persistence" && this.props.images && (
            <div className="MapboxMap__image-legend">
              <div className="MapboxMap__image-legend-label">
                <div> 100%</div>
                <div> 0%</div>
              </div>
              <div className="MapboxMap__image-legend-color"></div>
            </div>
          )}

          {this.props.datasetName==="threatened-species" && this.props.images && (
            <div className="MapboxMap__image-legend">
              <div className="MapboxMap__image-legend-label">
                <div> 100%</div>
                <div> 0%</div>
              </div>
              <div className="MapboxMap__image-legend-color-tree-height"></div>
            </div>
          )}
          
          {this.props.selectedArea && (
            <div
              className={
              this.props.showInteractive
                ? "MapboxMap__selectedArea_interactive"
                : "MapboxMap__selectedArea"
              }
            >
              Selected area size {this.props.selectedArea.toLocaleString()} ha.
            </div>
          )}
        </Mapbox>
      );
    }
  }
}

export default Mboxmap;
