import { makeAutoObservable, } from 'mobx';
import axios from 'axios'
import API_URL from '../config';
import { AuthType, UserType } from '../types';
import { parseJwt} from '../util'
export class AuthStore{
    loading: boolean = false
    authorized: boolean = false
    termsChosen: boolean = false
    username: string = ''
    email: string =''
    full_name: string = ''
    disabled: boolean = true
    errorMessage: string = ''
    authInfo: AuthType = {} as AuthType
    user: UserType = {} as UserType
    constructor() {
        makeAutoObservable(this)
        this.testAccess()
    }

    testAccess() {
        const config = {
            method: 'get',
            url: `${API_URL.ROOT_URL}/auth/access/`,
        }
       //console.log("from auth store 1: " + `${API_URL}/auth/access/`);
       //console.log("from auth store 2: " + `${API_URL.ROOT_URL}/auth/access/`);
        

        let tokenObj = localStorage.getItem('token')
        //No Token exist
        if(!tokenObj||(tokenObj&&tokenObj.length<=2)) {
            this.logout()
            return
        }
        const token = JSON.parse(tokenObj) as AuthType
        if (!token) {
            return
        }

        this.authInfo = token
        const tokenParsed = parseJwt(token.access_token)

        //Token expired
        if (tokenParsed['exp'] < Date.now() / 1000) {
            return
        }

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        //@ts-ignore
        return axios(config).then(res=>{
            if(res.status === 200){
                this.authorized = true
                const parsedIdToken =  parseJwt(token.id_token)
                this.user = parsedIdToken as UserType
                this.user.group = parsedIdToken['cognito:groups']
                this.user.username = parsedIdToken['cognito:username']
                localStorage.setItem('user', JSON.stringify(this.user))

            }else{
                console.log('auth check failed')
                this.logout()
            }
        }).catch((error: Error) => {
            console.error("🚀 ~ file: authStore.ts ~ line 59 ~ AuthStore ~ returnaxios ~ error", error)
            this.logout()
        })
    }

    setupToken(tokens: string){
        if (!tokens) {
            this.testAccess()
            return
        }

        this.loading = true
        const splits = tokens.split('&')
        let auth = {} as AuthType

        for (let split of splits) {
            const [key, value] = split.split('=')

            switch(key){
                case '#id_token':
                    auth.id_token = value; break;
                case 'access_token':
                    auth.access_token = value; break;
                case 'expires_in':
                    auth.expires_in = Number.parseInt(value); break;
                case 'token_type':
                    auth.token_type = value; break;
            }
        }

        this.authInfo = auth
        localStorage.setItem('token', JSON.stringify(auth))
        this.testAccess()
    }

    switchTermsChosen() {
        this.termsChosen = !this.termsChosen
    }

    logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        this.authorized = false
    }
}

export default new AuthStore()