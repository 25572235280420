import React, { FC } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { Link } from "react-router-dom";
import { Box,
    AppBar,
    Container,
    Toolbar,
    Typography,
    Tab,
    Tabs,
    IconButton,
    createStyles,
    Theme,
    makeStyles,
    Button
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { appBarRoutes, AppBarRouteType } from './helpers/constants';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    margin: {
      margin: theme.spacing(1),
      color: 'white'
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    fullHeight: {
        ...theme.mixins.toolbar,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    logo: {
        flexGrow: 3
    }
  }),
);

const LoocBAppBar: FC = () => {
    const classes = useStyles();
    // check to see if known list of routes matches the currently visited route
    const match = useRouteMatch(
        appBarRoutes.filter((item: AppBarRouteType) => item.label)
        .map((item: AppBarRouteType) => item.value)
    )
    // if it is not the home/root route use the match route, if it is use the introduction route
    const currentTab = window.location.pathname !== "/" ? match?.path : '/introduction'

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" className={classes.appBar}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img
                            width={35}
                            height={35}
                            className={classes.extendedIcon}
                            src="https://looc-c.farm/static/media/csiro-white-logo.550c139b.svg"
                        />
                        <Typography noWrap variant="h6" component="div" className={classes.logo}>
                            LOOC-B
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Route path="/">
                                <Tabs
                                    classes={{ root: classes.fullHeight }}
                                    value={currentTab === undefined ? false : currentTab}
                                >
                                    {appBarRoutes?.length > 0
                                        && appBarRoutes.map((item: AppBarRouteType) => {

                                        return (
                                            <Tab
                                                to={item.to}
                                                key={item.value}
                                                value={item.value}
                                                label={item.label}
                                                component={Link}
                                                classes={{ root: classes.fullHeight }}
                                            />
                                        )
                                    })}
                                </Tabs>
                            </Route>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }}}>
                            <IconButton to="/profile" component={Link} color="inherit">
                                <AccountCircle />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}

export default LoocBAppBar;
