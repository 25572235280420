import React, { MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import EstimateResult from "./EstimateResult";
import { Box, Button, Slide } from "@material-ui/core";
import useDraw, { DrawAction } from "./hooks/useDraw";
import estimateStore from "../stores/estimateStore";

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 2,
        overflowY: 'scroll',
        padding: theme.spacing(3),
    },
    button: {
        width: '13rem',
        marginBottom: '1.5rem'
    },
    toolbar: theme.mixins.toolbar,   
}));

const RightDrawer = observer(() => {
    const classes = useStyles();
    const { estimateDraw, handleDrawClick } = useDraw()

    const handleHideDraw = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        estimateStore.handleResetToggleShowHideEstimate()
        handleDrawClick(event, DrawAction.CLOSE)
    }

    return (
        <Slide
            mountOnEnter
            unmountOnExit 
            direction="left"
            in={estimateDraw}
            style={{
                width: estimateStore.loading ? '100rem' : '1120rem',
                height: `${100 - (64 * 100 / window.innerHeight)}vh`
            }}
        >
            <Box className={classes.drawer}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="primary"
                        variant='contained'
                        onClick={handleHideDraw}
                        className={classes.button}
                    >
                        Estimate Again
                    </Button>
                    <EstimateResult />
                </Box>
            </Box>
        </Slide>
    );
})
export default RightDrawer
