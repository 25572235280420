import { LatLng, Polygon, MapboxPolyCoords } from '../types'

export enum DrawStatus {
    Disable,
    Enable
  }
  export enum PolygonStatus {
    Invalid,
    Valid
  }
  
export enum MapHelpMessage {
    Init = "Click area tool to start drawing evaluation area",
    DrawPolygon = "Click/tap the points around the boundary of your land area",
    CompletePolygon = "Evaluation area is defined, delete to redraw",
    EditPolygon = "Click anywhere outside area to exit edit mode",
    UnchoosePolygon = "Click the area to choose for delete or edit"
  }

export const generatePolygonDrawLayer = (polygon: Polygon) => {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [polygon]
          }
        }
      ]
    };
  };
  
  export const generatePolygonLayer = (polygon: Polygon) => {
    return {
      id: "savedPolygon",
      type: "fill",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [polygon]
          }
        }
      },
      layout: { visibility: "visible" },
      paint: {
        "fill-color": "#8c8c8c",
        "fill-opacity": 0.7
      }
    };
  };


  export const disablePolygonDrawTool = (
    disable: DrawStatus,
    isValidPologon: PolygonStatus
  ) => {
    if (disable === DrawStatus.Enable) {
      // if the ploygon is valid, disable it, or else, keep it valid
      if (isValidPologon === PolygonStatus.Valid) {
        // Disable drawing tool
        disableDrawButton();
    //   } else {
        // Enable draw tool
        const buttons: HTMLCollection = document.getElementsByClassName(
          "mapbox-gl-draw_line"
        );
        console.log(buttons)
        buttons[0].removeAttribute("disabled");
        buttons[0].setAttribute("title", "Area tool");
      }
    } else {
      // Disable drawing tool
      disableDrawButton();
    }
  };
  
  export const disableDrawButton = () => {
    const button = document.activeElement;
    if (button && button.classList.contains("mapbox-gl-draw_line")) {
      button.classList.remove("active");
      button.setAttribute("disabled", "disabled");
      button.setAttribute("title", "Area tool is disabled");
    } else {
      const buttons: HTMLCollection = document.getElementsByClassName(
        "mapbox-gl-draw_line"
      );
      buttons[0].setAttribute("disabled", "disabled");
      buttons[0].setAttribute("title", "Area tool is disabled");
    }
  };
  
  export const disablePolygonDeleteTool = (disable: boolean) => {
    if (disable) {
      // Disable drawing tool
      const button = document.activeElement;
      if (button && button.classList.contains("mapbox-gl-draw_trash")) {
        button.classList.remove("active");
        button.setAttribute("disabled", "disabled");
        button.setAttribute("title", "Delete tool is disabled");
      } else {
        const buttons: HTMLCollection = document.getElementsByClassName(
          "mapbox-gl-draw_trash"
        );
        buttons[0].setAttribute("disabled", "disabled");
        buttons[0].setAttribute("title", "Delete tool is disabled");
      }
    } else {
      // Enable drawing tool
      const buttons: HTMLCollection = document.getElementsByClassName(
        "mapbox-gl-draw_trash"
      );
      buttons[0].removeAttribute("disabled");
      buttons[0].setAttribute("title", "Delete tool");
    }
  };
  
  
  
  export const setDrawModeToSelect = (map: any) => {
    //@ts-ignore
    const drawcontrol = map._controls.filter(c => c.modes);
    const allFeatures = drawcontrol[0].getAll().features;
    if (allFeatures.length > 0) {
      drawcontrol[0].changeMode("simple_select", {
        featureIds: [allFeatures[0].id]
      });
    }
  };