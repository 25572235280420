 const a:number = 1

 export const parseJwt = (token: string) => {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  }

export const isObjectEmpty = (obj:any) => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype

export default {a}