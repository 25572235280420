import React, { FC, useState, MouseEvent } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import { RouteProps  } from "react-router-dom";
import './App.css';
import Introduction from './pages/Introduction';
import authStore from './stores/authStore';
import { observer } from 'mobx-react-lite';
import Login from './pages/Login';
import Profile from './pages/Profile';
import About from './pages/About';
import Dashboard from './pages/Dashboard';
import Terms from './pages/Terms';
import LoocBAppBar from './components/loocbAppBar';
import useDraw, { DrawAction } from './components/hooks/useDraw';

//import logo from './resources/csiro-white-logo.550c139b.svg';

interface Props extends RouteProps {
    component: any;
}

const ProtectedRoute: FC<Props> =
    ({ component: Component, ...rest }) =>
        <Route
            {...rest}
            render={(props)=> authStore.authorized === true
                ? <Component />
                : <Redirect to='/login' />
            }
        />

const App: FC = () => {
    const { handleDrawClick } = useDraw()

    const [open, setOpen] = useState(false);
    
    const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
        handleDrawClick(event, DrawAction.CLOSE);
    };
    
    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        handleDrawClick(event, DrawAction.OPEN);
    };

    return (
        <div>
            <LoocBAppBar />

            <Switch>
                <Route exact path='/' component={Introduction} />
                <Route exact path='/about' component={About} />
                <Route exact path='/estimate' component={Dashboard} />
                <Route exact path='/introduction' component={Introduction} />
                <ProtectedRoute path='/profile' component={Profile} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/terms' component={Terms} />
            </Switch>
            <Dialog open={open}>
                <DialogTitle>This is a prototype. Help us make it better</DialogTitle>
                <DialogContent>
                    <li>Report a bug or something that doesn't seem right, click this <a target="blank" href="https://www.surveymonkey.com/r/S3BV72D">link</a></li>
                    <li>Feedback about your registration experience and using the web based interface, click this <a target="blank" href="https://www.surveymonkey.com/r/WV7V7GS">link</a></li>
                    <li>Feedback about your registration experience and using the API, click this <a target="blank" href="https://www.surveymonkey.com/r/S3YM7RS">link</a></li>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="primary" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* <Fab color="primary" variant="extended" className={classes.speedDial} onClick={handleOpen}>
                <PlaylistAddCheckOutlined className={classes.extendedIcon}/>
                Survey
            </Fab> */}
            {/* <Container style={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                    © {new Date().getFullYear()} Example project | All Rights Reserved.
                </Grid >
            </Container> */}

        </div>
    );
}

export default observer(App);
