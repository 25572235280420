import { /* observable, action, computed, reaction, */ makeAutoObservable, } from 'mobx';
// import { PlanningActionType, ModeType } from '../types';
import { DataLayerType, ModeType, PlanningActionType } from '../types';
import estimateStore from './estimateStore';
import mapboxStore from './mapboxStore';
// import API_URL from '../config'



export class EstimateContextStore{
    availableYearRange: Array<string> = []
    defaultStartYear: string = '2004'
    //dataset: Array<string> = []
    //defaultStartYear: string = (this.dataset[0] == 'biodiversity-persistence') ? '2004' : '2012';
    startYear: string = this.defaultStartYear
    // startYear: string = (this.dataset.includes(DataLayerType.BiodiversityPersistence)) ? '2004' : '2012';
    defaultEndYear: string = '2020'
    endYear: string = this.defaultEndYear
    actionYear: string | undefined
    dataset: Array<string> = []
    mode: ModeType | undefined
    planningActionType: PlanningActionType | undefined


    constructor(){
        makeAutoObservable(this)
    }

    setDataset(dataset: string) {
        /* Only allow a single entry to the array for now
        const idx = this.dataset.findIndex(ele=>ele===dataset)
        console.log(idx)
        if(idx>=0){
            this.dataset.splice(idx, 1)
        }else{
            this.dataset = this.dataset.concat([dataset])
        }
        */
       this.dataset = [dataset]

    }

    setMode(mode: ModeType | undefined){
        this.mode = mode
    }

    setPlanningActionType(action: PlanningActionType | undefined){
        this.planningActionType = action
    }

    setStartYear(year: string){
        this.startYear = year
    }

    setEndYear(year: string){
        this.endYear = year
    }

    setActionYear(year: string){
        this.actionYear = year
    }

    resetAllRadioSelectValues() {
        this.dataset = []
        this.mode = undefined
        this.planningActionType = undefined
        mapboxStore.handleResetAllData()
        estimateStore.handleToggleShowHideEstimateDataSet()
    }
}

export default new EstimateContextStore()