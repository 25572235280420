import React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    TextareaAutosize,
    CircularProgress
} from '@material-ui/core'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import authStore from '../stores/authStore';
import { Redirect } from 'react-router-dom';
import LeftDrawer from '../components/LeftDrawer';
import RightDrawer from '../components/RightDrawer';
import Mboxmap from "../components/Map/MapboxMap";
import mapboxStore from '../stores/mapboxStore'
import estimateStore from '../stores/estimateStore';
import useDraw from '../components/hooks/useDraw';

const style = "mapbox://styles/mapbox/satellite-streets-v10?optimize=true"
const mapStyle = {
    height: `${100 - 64 * 100 / window.innerHeight}vh`,
    width: "100%",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        layout: {
            marginTop: 64,
            display: 'flex',
            overflowY: 'hidden',
            flexDirection: 'row',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

const Dashboard = observer(() => {
    const classes = useStyles();
    const { estimateDraw } = useDraw()

    if (!estimateDraw && estimateStore.loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '64px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: `${2}rem ${2}rem ${0}rem ${0}rem `
                }}
            >
                <CircularProgress />
            </Box>
        )
    } 
    if (!estimateStore.loading && !authStore.authorized) {
        return (
            <Redirect from="/estimate" to='/login' />
        )
    }

    const estimateImages = estimateStore.planningEstimateResult.datasets
        ? estimateStore.planningEstimateResult.datasets[0].geotiff.images
        : undefined

    const bounds = estimateStore.planningEstimateResult.datasets
        ? estimateStore.planningEstimateResult.datasets[0].geotiff.bounds
        : undefined

    const imageCoords = bounds
        ? [[bounds.left, bounds.top], [bounds.right, bounds.top], [bounds.right, bounds.bottom], [bounds.left, bounds.bottom]]
        : undefined

    const datasetName = estimateStore.planningEstimateResult.datasets
        ? estimateStore.planningEstimateResult.datasets[0].name
        : undefined

    return (
        <div className={classes.layout}>
           <LeftDrawer />
            <div style={{ width: '100%' }}>
                <Mboxmap
                    center={mapboxStore.center}
                    coords={mapboxStore.polygons[0] || []}
                    style={style}
                    mapStyle={mapStyle}
                    onZoomChanged={mapboxStore.onZoomChanged}
                    onDrawCreate={mapboxStore.onDrawCreate}
                    onDrawUpdate={mapboxStore.onDrawUpdate}
                    onDrawDelete={mapboxStore.onDrawDelete}
                    onDrawModeChange={mapboxStore.onDrawModeChange}
                    onDrawSelectionChange={mapboxStore.onDrawSelectionChange}
                    onLoadFile={mapboxStore.onLoadFileModalOpen}
                    onDownloadFile={mapboxStore.onDownloadFile}
                    setDrawControl={mapboxStore.setDrawControl}
                    showInteractive={true}
                    helperMessage={mapboxStore.helperMessage}
                    selectedArea={mapboxStore.area}
                    initPolygon={mapboxStore.polygons[0]}
                    images={estimateImages}
                    imageCoords={imageCoords}
                    datasetName={datasetName}
                    showHideEstimateDataSetImageLayer={estimateStore.showHideEstimateDataSet}
                />
            </div>
            <RightDrawer />
            <Dialog
                    open={mapboxStore.areaLargeAlert}
                    onClose={mapboxStore.closeAreaLargeAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Your Selected Area is Too {mapboxStore.area>1000000? 'Large': 'Small'}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your Selected size is <b>{mapboxStore.area.toLocaleString()} ha</b>. <br/>
                        Suggested selected area is more than 1 ha and less than 1,000,000 ha. The larger area means longer process time. 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={mapboxStore.closeAreaLargeAlert} color="primary" autoFocus>
                        Select Area Again
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={mapboxStore.loadFileModelOpen}
                    maxWidth={'md'}
                    fullWidth={true}
                    onClose={mapboxStore.onLoadFileModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Load Polygon from a file</DialogTitle>
                    <DialogContent>
                        <div>
                            <div>
                                <TextField
                                    fullWidth
                                    type="file"
                                    variant="outlined"
                                    id="standard-basic"
                                    label="Select a GeoJSON file"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={mapboxStore.onLoadFileChange}
                                /> 
                            </div>
                            <br/>
                            <div>
                                <TextareaAutosize
                                    minRows={10}
                                    maxRows={60}
                                    style={{width: '100%'}}
                                    placeholder="Paste geojson content here ..."
                                    value={mapboxStore.fileContent}
                                    onChange={mapboxStore.onFileContentChange}
                                />
                            </div>
                        </div>
                        <div style={{color: 'red'}}>
                            <ul>
                                {
                                    mapboxStore.fileContentVerify.length> 0 ? mapboxStore.fileContentVerify.map(ele => <li key={ele}>{ele}</li> ) : ''
                                }
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={mapboxStore.onLoadFileModalClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={mapboxStore.onLoadFileSubmit} color="primary" autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>
        </div>)
}
)

export default Dashboard