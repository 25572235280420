import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link, Redirect , useLocation  } from 'react-router-dom'
import { observer } from "mobx-react-lite";
import { TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import authStore from '../stores/authStore';
import { useEffect } from 'react';
import { REACT_APP_COGNITO_CLIENT_ID } from '../config'

type LocationState = {
  from: {
    path: string;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems:'center',
      marginTop: 80,
    },
    margin: {
      margin: theme.spacing(1),
      width: 400
    },
    button: {
      margin: 20
    }
  }),
);

const Login = observer((props)=>{
    const classes = useStyles();
    const location = useLocation<LocationState>();
    //@ts-ignore
    authStore.setupToken(props.location.hash)

    //console.log(`https://biodiversity-dev.auth.ap-southeast-2.amazoncognito.com/login?client_id=${REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${window.location.origin}/login`);
    
    const { from } = location.state || { from: { path: '/' } }
    if (authStore.authorized) {
      return (
        <Redirect to={from.path} />
      )
    }

    return(
    <div className={classes.root}>
        <p>Please click the following "Login" button to login or sign up</p>
        <p>
          If you have any issues, please contact <a href="mailto:looc-b@csiro.au">looc-b@csiro.au</a>
        </p>
        <Button
            variant="outlined"
            className={classes.button}
            disabled={!authStore.termsChosen}
            href={`https://biodiversity-dev.auth.ap-southeast-2.amazoncognito.com/login?client_id=${REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${window.location.origin}/login`}
          >
            Login / Sign Up
        </Button>
        <FormControlLabel
          label="I agree to the terms and conditions"
          control={<Checkbox onChange={()=>authStore.switchTermsChosen()} />}
        />
        <p>
          <Link to='/terms'>Terms and Conditions</Link>
        </p>
    </div>)
})

export default Login