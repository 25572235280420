import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {InputLabel, MenuItem, FormControl, Select, Radio, RadioGroup, FormControlLabel, Checkbox, FormGroup, Divider} from '@material-ui/core';
import { observer } from "mobx-react-lite";
import estimateContextStore from '../stores/estimateContextStore';
import { DataLayerType, ModeType, PlanningActionType } from '../types';
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
    //   margin: theme.spacing(1),
    //   minWidth: 120,
      width: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    dateRange: {
        display: 'flex',
        displayDirection: 'row'
    }
  }),
);

function getYearRange(startYear: string, endYear: string){
    let range: Array<number> = []
    console.log(range, startYear, endYear)
    for(let start = parseInt(startYear); start <= parseInt(endYear); start = start + 1){
        range.push(start)
    }
    return range
}

const EstimateContext = observer(()=>{
    const classes = useStyles();
    const yearRange = getYearRange(estimateContextStore.defaultStartYear, estimateContextStore.defaultEndYear) || []
    
    return (
      <div>
        <h3>Select Mode</h3>
        <FormControl className={classes.formControl}>
            <RadioGroup aria-label="mode" name="row-radio-buttons-group" 
                onChange={(event) => estimateContextStore.setMode(event.target.value as ModeType)}
                value={estimateContextStore.mode ?? false}>
                <FormControlLabel value="planning" control={<Radio />} label="Planning a change" />
                <FormControlLabel value="monitoring" control={<Radio />} label="Monitoring a change that has occurred" />
            </RadioGroup>
        </FormControl>
        <Divider />

        <h3>Select biodiversity indicator</h3>
        <FormControl className={classes.formControl}>
            <RadioGroup
                aria-label="mode"
                name="row-radio-buttons-group"
                onChange={(event) => estimateContextStore.setDataset(event.target.value)}
            >
                <FormControlLabel 
                    control={<Radio />}
                    label="Habitat condition"
                    value={DataLayerType.HabitatCondition}
                    checked={!!estimateContextStore.dataset.includes(DataLayerType.HabitatCondition)}
                    onChange={(event) => estimateContextStore.setStartYear("2004")}
                />
                <FormControlLabel
                    control={<Radio />}
                    // label={<p>Habitat connectivity<span style={{color: 'gray'}}> (coming soon)</span></p>}
                    label={<p>Habitat connectivity {estimateContextStore.mode === ModeType.planning ? "(coming soon)" : ""}</p>}
                    value="habitat-connectivity"
                    disabled={estimateContextStore.mode === ModeType.planning}
                    checked={!!estimateContextStore.dataset.includes(DataLayerType.HabitatConnectivity)}
                    onChange={(event) => estimateContextStore.setStartYear("2012")}
                />
                <FormControlLabel
                    control={<Radio />}
                    // label={<p>Biodiversity persistance {estimateContextStore.mode === ModeType.planning ? "(only available for monitoring mode)" : ""}</p>}
                    label={<p>Biodiversity persistance</p>}
                    value="biodiversity-persistence"
                    // disabled={estimateContextStore.mode === ModeType.planning}
                    checked={!!estimateContextStore.dataset.includes(DataLayerType.BiodiversityPersistence)}
                    onChange={(event) => estimateContextStore.setStartYear("2004")}
                />
                <FormControlLabel
                    control={<Radio />}
                    label={<p>Tree height {estimateContextStore.mode === ModeType.planning ? "(only available for monitoring mode)" : ""}</p>}
                    // label={<p>Tree height<span style={{color: 'gray'}}> (coming soon)</span></p>}
                    // label={<p>Tree height<span style={{color: 'gray'}}> (coming soon)</span></p>}
                    value="tree-height"
                    disabled={estimateContextStore.mode === ModeType.planning}
                    checked={!!estimateContextStore.dataset.includes(DataLayerType.TreeHeight)}
                    onChange={(event) => estimateContextStore.setStartYear("2012")}
                />
                <FormControlLabel
                    control={<Radio />}
                    // label={<p>Threatened species {estimateContextStore.mode === ModeType.planning ? "(only available for monitoring mode)" : ""}</p>}
                    label={<p>Threatened species</p>}
                    value="threatened-species"
                    // disabled={estimateContextStore.mode === ModeType.planning}
                    checked={!!estimateContextStore.dataset.includes(DataLayerType.ThreatenedSpecies)}
                    onChange={(event) => estimateContextStore.setStartYear("2004")}
                />
            </RadioGroup>
        </FormControl>

        <Divider />
        {estimateContextStore.mode === ModeType.planning && (
            <>
                <h3>Select type of event or action</h3>
                <FormControl className={classes.formControl}>
                    <RadioGroup
                        aria-label="mode"
                        name="row-radio-buttons-group" 
                        onChange={(event) => estimateContextStore.setPlanningActionType(event.target.value as PlanningActionType)}
                        value={estimateContextStore.planningActionType ?? ''}
                    >
                        <FormControlLabel value="environmental_planting" control={<Radio />} label="Environment planting" checked={PlanningActionType.EnvironmentPlanting === estimateContextStore.planningActionType ?? false}/>
                        <FormControlLabel value="plantation_native" control={<Radio />} label="Plantation native" checked={PlanningActionType.PlantationNative === estimateContextStore.planningActionType ?? false}/>
                        <FormControlLabel value="plantation_exotic" control={<Radio />} label="Plantation exotic" checked={PlanningActionType.PlantationExotic === estimateContextStore.planningActionType ?? false}/>
                        <FormControlLabel value="natural_regeneration" control={<Radio />} label="Natural regeneration" checked={PlanningActionType.NaturalRegeneration === estimateContextStore.planningActionType ?? false} />
                    </RadioGroup>
                    <Divider />
                </FormControl>
            </>
        )}
        {
            estimateContextStore.mode === ModeType.monitoring && <>
            <h3>Select estimate date range</h3>
            <div className={classes.dateRange}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="start-year-label">
                        Start year
                    </InputLabel>
                    <Select
                        labelId="start-year-label"
                        id="start-year"
                        fullWidth
                        value={estimateContextStore.startYear ?? ''}
                        //value={(estimateContextStore.dataset.includes(DataLayerType.BiodiversityPersistence)) ? '2004' : '2012'}
                        onChange={(event)=>estimateContextStore.setStartYear(event.target.value as string||'2012')}
                    >
                        {yearRange.map(year => <MenuItem value={year} key={year}>{year}</MenuItem>)}

                    </Select>
                </FormControl>
                <div style={{ margin: '1em 2em'}}>to</div>
                <FormControl className={classes.formControl}>
                    <InputLabel id="end-year-label">
                        End year
                    </InputLabel>
                    <Select
                        labelId="end-year-label"
                        id="end-year"
                        fullWidth
                        value={estimateContextStore.endYear ?? ''}
                        onChange={(event)=>estimateContextStore.setEndYear(event.target.value as string||'2012')}
                    >
                        {yearRange.filter(ele=>ele>=parseInt(estimateContextStore.startYear))
                            .map(year => <MenuItem value={year} key={year}>{year}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>
            <Divider />
            <h3>Select action year</h3>
            <FormControl className={classes.formControl}>
                <InputLabel id="action-year-label">Action year</InputLabel>
                <Select
                    labelId="action-year-label"
                    id="action-year"
                    fullWidth
                    value={estimateContextStore.actionYear ?? ''}
                    onChange={(event)=>estimateContextStore.setActionYear(event.target.value as string||'2012')}
                >
                    {yearRange.filter(ele => ele >= parseInt(estimateContextStore.startYear)
                        && ele <= parseInt(estimateContextStore.endYear)).map(year =>
                            <MenuItem value={year} key={year}>
                                {year}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
            </>
        }
      </div>
    );
})

export default EstimateContext