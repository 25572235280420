import { useCallback, MouseEvent } from 'react'
import estimateStore from '../../../stores/estimateStore';

export enum DrawAction {
  OPEN,
  CLOSE
}

const useDraw = () => {
    const estimateDraw = estimateStore.estimateDrawOpen

  const handleDrawClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>, drawAction: DrawAction) => {
      event.preventDefault()
      event.stopPropagation()
      estimateStore.toggleEstimateRightDraw(drawAction)
    },
    [estimateDraw],
  )

  const handleMouseScroll = (
    event: WindowEventMap | HTMLElementEventMap | Event, drawAction: DrawAction
  ) => {
    estimateStore.toggleEstimateRightDraw(drawAction)
  }

//   const handleMouseAway = useCallback((event: MouseEvent | Event) => {
//     estimateStore.toggleEstimateRightDraw(false)
//   }, [])

//   useEventListener('click', handleMouseAway)
  // useEventListener('scroll', handleMouseScroll)

  return {
    estimateDraw,
    handleDrawClick,
  }
}

export default useDraw