import { action, makeAutoObservable } from "mobx";
import mapboxgl, { Map, LngLatBounds } from "mapbox-gl";
import gjv  from './geojsonValidation'
import {
    generatePolygonDrawLayer,
    disablePolygonDrawTool,
    disablePolygonDeleteTool,
    setDrawModeToSelect,
    DrawStatus,
    PolygonStatus,
    MapHelpMessage,
    generatePolygonLayer
} from "./mapboxUtil";
import { LatLng, Polygon, MapboxPolyCoords } from '../types'
import { requests } from "./apiagent";
import API_URL from "../config";
import { ChangeEvent, ChangeEventHandler } from "react";
import DrawControl from "react-mapbox-gl-draw";
import estimateStore from "./estimateStore";
import { DrawAction } from "../components/hooks/useDraw";

class MapboxStore {
    AUcenter: LatLng = { lat: -28.25, lng: 133.416667 };
    AUbounds = new LngLatBounds([[90, -50], [180, -10.47]]); // [sw,ne]
    AUSearchBounds = [90, -44, 180, -10]; // minLon,minLat,maxLon,maxLat
    zoom: number = 3
    mapZoomCenterReset: boolean = false
    center: MapboxPolyCoords = [this.AUcenter.lng, this.AUcenter.lat]

    polygons: Array<Polygon> = []
    area: number = 0
    areaLargeAlert: boolean = false
    helperMessage: MapHelpMessage = MapHelpMessage.Init
    drawControl: DrawControl | null = null

    loadFileModelOpen: boolean = false
    fileContent: string = ''
    fileContentVerify: Array<string> = []
    mapActual: Map = {} as Map // need to cast here to keep .ts happy ad Map doesn't have an undefined type to initiate

    constructor() {
        makeAutoObservable(this)
    }

    setMapActual(map: Map) {
        this.mapActual = map;

        return this.mapActual
    }

    getCenterOfPolygon() {
        if (this.polygons.length === 0) {
            return this.AUcenter
        } else {
            let bounds = new LngLatBounds()
            this.polygons[0].forEach(ele => bounds.extend(ele))

            return bounds.getCenter()
        }
    }

    handleResetAllData() {
        this.mapActual.setZoom(3);

        this.fileContent = ''
        this.polygons = []
        this.drawControl = null
        this.fileContentVerify = []
        this.mapZoomCenterReset = true;

        estimateStore.resetPlaceImagesAndPlanningEstimateResult()

        if (estimateStore.estimateDrawOpen) {
            estimateStore.toggleEstimateRightDraw(DrawAction.CLOSE)
        }
        // I think the below code is a little risky, but it works, and it follows the current standard of code for adding the geo fence to the map.
        // but in this case we are removing it. See "drawcontrol" variable in MapboxMap.tsx "loadMap" function.
        const drawcontrol = (this.mapActual as any)._controls.filter((c: any) => c.delete);

        // Remove the geofence from the map EVERY TIME the file upload modal is open.
        // This ensures we clear any drawn or uploaded data on file upload.
        drawcontrol[0].deleteAll()
    }

    onLoadFileModalOpen = () => {
        // most of the below code makes sure all "state" and data is reset before uploading new map data.
        // Data reset will only happen if there is "fileContent"/data already existing,
        // meaning not on the first/initial upload of new map data.
        if (this.fileContent) {
            this.mapActual.setZoom(3);
            this.handleResetAllData()
        } else {
            this.mapActual.setZoom(3);
        }

        this.loadFileModelOpen = true
    }

    onLoadFileChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const input = event.target as HTMLInputElement

        if (input.files && input.files.length > 0) {
            const file = input.files[0]
            file.text().then(
                action(text => this.fileContent = text)
            )
        }

    }

    onFileContentChange = (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
        const input = event.target as HTMLTextAreaElement
        this.fileContent = input.value
    }

    onLoadFileModalClose = () => {
        this.loadFileModelOpen = false
    }

    onLoadFileSubmit = () => {
        let messages = this.verifyGeoJSON(this.fileContent)

        if (messages.length === 0) {

            let jsonObj = JSON.parse(this.fileContent)
            this.savePolygon(jsonObj['features'][0]['geometry']['coordinates'][0])
            this.loadFileModelOpen = false

            disablePolygonDrawTool(
                DrawStatus.Disable,
                PolygonStatus.Valid
            );

            disablePolygonDeleteTool(false);

            this.repaintDraw(jsonObj)
            //this.fitPolyBounds(jsonObj)
        } else {
            this.fileContentVerify = messages
        }
    }
    
    verifyGeoJSON(geojson: string): Array<string>{
        let jsonObj
        try {
            jsonObj = JSON.parse(geojson)
        } catch {
            return ['Invalid JSON ']
        }

        let messages = gjv.isGeoJSONObject(jsonObj, true)
        //@ts-ignore
        if (messages.length === 0) {
            let polygonVerify = gjv.isPolygon(jsonObj['features'][0]['geometry'], true)
            console.log(jsonObj)
            //@ts-ignore
            if (polygonVerify.length === 0) {
                return []
            } else {
                //@ts-ignore
                return polygonVerify
            }
        } else {
            //@ts-ignore
            return messages
        }
    }

    savePolygon = (coords: Polygon) => {
        this.polygons = [coords]
        this.queryAreaSize()
    }

    onDownloadFile = () =>{
        const a = document.createElement('a');
        let content = this.fileContent
        if(!content){
            content = JSON.stringify(generatePolygonDrawLayer(this.polygons[0]))
        }
        const file = new Blob([content], {type: 'application/json'});
        
        a.href= URL.createObjectURL(file);
        a.download = (new Date()).toISOString()+'.json';
        a.click();
    }

    updateHelperBox = (text: MapHelpMessage) => {
        this.helperMessage = text
    }

    clearPolygons = () => {
        this.polygons = []
        // this.area = 0
    }

    setDrawControl = (drawControl: DrawControl|null) => {
        this.drawControl = drawControl
    }

    repaintDraw = (geojson: JSON) =>{
        if (!this.drawControl) {
            return
        }
        //@ts-ignore
        this.drawControl.draw && this.drawControl.draw.deleteAll()
        //@ts-ignore
        this.drawControl.draw && this.drawControl.draw.add(geojson)
        this.fitBounds(this.drawControl.context)
        console.log(this.drawControl.context);
    }

    fitBounds = (map: mapboxgl.Map | undefined) => {
        if (map) {
            // this is the code that will take the user to there geofence,
            // once they have submitted it zooming in no more then 10,
            // aka large roads
            // REF:: definition of zoom level from mapbox.
            // https://docs.mapbox.com/help/glossary/zoom-level/
            // map.flyTo({ center: this.center, zoom: 10 });
    
            const bounds = new LngLatBounds();
            if (this.polygons[0].length > 0) {
                this.polygons[0].forEach(ele => bounds.extend(ele))
            }

            this.mapActual.fitBounds(bounds, {
                padding: { top: 10, bottom: 10, left: 10, right: 10 },
                animate: true
            })
        }

    }

    onZoomChanged = (map: mapboxgl.Map) => {
        this.saveMapView(this.mapActual);
    };

    onDrawCreate = (draw: any) => {
        const features = draw.features;

        if (features && features.length > 0) {
            const feature = features[0];

            if (feature.geometry.type === "LineString") {
                const coords = feature.geometry.coordinates as Array<Array<Number>>;
                const firstCoord = coords[0];
                const lastCoord = coords[coords.length - 1];

                // auto close the polygon when double click or press enter
                if (firstCoord[0] !== lastCoord[0] || firstCoord[1] !== lastCoord[1])
                    coords.push(firstCoord);

                // prevent user finish draw (double click or click to the start point) earier before finish polygon
                if (coords.length > 3) {
                    // convert line string drawing to polygon
                    //@ts-ignore
                    const drawcontrol = draw.target._controls.filter(c => c.modes); //just hope nothing else has a 'modes' prop
                    if (drawcontrol && drawcontrol[0]) {
                        // delete line strings
                        drawcontrol[0].delete(feature.id);
                        // add generated polygon layer to draw control
                        const feature_id = drawcontrol[0].add(
                            generatePolygonDrawLayer(coords as Polygon)
                        );
                        // Use changeMode method to force the draw tool change mode with polygon selected
                        drawcontrol[0].changeMode("simple_select", {
                            featureIds: feature_id
                        });

                        this.savePolygon(coords as Polygon)
                        disablePolygonDrawTool(
                            DrawStatus.Disable,
                            PolygonStatus.Valid
                        );
                        disablePolygonDeleteTool(false);
                        //   ReactGA.event(completePolygon(polygonFormat));
                    }
                } else {
                    // @ts-ignore
                    const drawcontrol = draw.target._controls.filter(c => c.modes); //just hope nothing else has a 'modes' prop
                    // delete all line strings if they are not valid
                    if (drawcontrol && drawcontrol[0]) drawcontrol[0].deleteAll();
                }
            }
        }
    };

    // @ts-ignore
    onDrawUpdate = (draw: any) => {
        const features = draw.features;

        if (features.length > 0) {
            const coords = features[0].geometry.coordinates[0]
            // let polygonFormat: Polygon = coords as Polygon
            this.clearPolygons();
            this.savePolygon(coords);
        }
    };

    onDrawDelete = () => {
        this.clearPolygons();
        this.updateHelperBox(MapHelpMessage.Init);

        disablePolygonDrawTool(
            DrawStatus.Enable,
            PolygonStatus.Valid
        );
        disablePolygonDeleteTool(true);

        if (this.drawControl) {
            //@ts-ignore
            this.drawControl.draw.deleteAll()
        }
    };

    saveMapView(map: Map) {
        this.zoom = this.mapActual.getZoom();
        const center = this.mapActual.getCenter();
        this.center = [center.lng, center.lat]
    }

    onDrawSelectionChange = (draw: any) => {
        this.saveMapView(draw.target);

        if (draw.features.length === 0) {
            setDrawModeToSelect(draw.target);
        }
    };

    onDrawModeChange = (draw: any) => {
        disablePolygonDeleteTool(true);
        this.saveMapView(draw.target);

        if (draw.mode === "draw_line_string") {
            // disable polygon drawing tool on the tool is clicked
            disablePolygonDrawTool(
                DrawStatus.Disable,
                PolygonStatus.Valid
            );
            this.updateHelperBox(MapHelpMessage.DrawPolygon);
        } else if (draw.mode === "simple_select") {
            //@ts-ignore
            const drawcontrol = draw.target._controls.filter(c => c.modes); //just hope nothing else has a 'modes' prop
            const features = drawcontrol[0].getAll().features;
            // update text and disable area tool when the polygon added success
            if (features && features.length > 0) {
                this.updateHelperBox(MapHelpMessage.CompletePolygon);
                setDrawModeToSelect(draw.target);
                disablePolygonDeleteTool(false);
            } else {
                // init the text and area tool if the feature is empty
                this.updateHelperBox(MapHelpMessage.Init);
                disablePolygonDrawTool(
                    DrawStatus.Enable,
                    PolygonStatus.Valid
                );
                disablePolygonDeleteTool(true);
            }
        } else if (draw.mode === "direct_select") {
            // this.updateHelperBox(MapHelpMessage.EditPolygon)
            setDrawModeToSelect(draw.target);
            disablePolygonDeleteTool(false);
        }
    };

    queryAreaSize() {
        let data = this.polygons[0].map(ele => ({lng: ele[0], lat: ele[1]}))

        requests.post(
            `${API_URL.ROOT_URL}${API_URL.AREA}`, {
                polygon: JSON.stringify(
                    generatePolygonDrawLayer(this.polygons[0])
                )
            })
            .then(
                action('queryAreaSize', res => {
                //@ts-ignore
                const size = res['area']
                this.area = Math.round(size)
                if (size > 1000000) {
                    this.areaLargeAlert = true
                }

                if (size <= 1) {
                    this.areaLargeAlert = true
                }
            })
        )
    }

    closeAreaLargeAlert = () =>{ 
        this.onDrawDelete()
        this.areaLargeAlert = false
    }
}

export default new MapboxStore();
