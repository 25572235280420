import {  Container } from '@material-ui/core'

const Terms = () => {

    return(
        <Container style={{ marginTop: 100 }}>
            <h2>Terms and Conditions</h2>
            <div>
    <div>
        <p>THIS AGREEMENT SETS OUT THE TERMS THAT APPLY TO YOUR ACCESS OF THE&nbsp;LOOC-B SERVICE&nbsp;(&lsquo;SERVICE&rsquo;) AND ANY&nbsp;DATA&nbsp;AVAILABLE THROUGH IT.&nbsp;</p>
    </div>
    <div>
        <p>CSIRO provides this Service as part of its research&nbsp;and development of&nbsp;bio-diversity tools. &nbsp;The Service&nbsp;and Licensed&nbsp;Data&nbsp;are provided on a trial basis, and CSIRO makes no warranty regarding their accuracy or&nbsp;suitability for any purpose. &nbsp;You must only use the Service and Licensed&nbsp;Data&nbsp;for internal research and at your own risk.&nbsp; CSIRO maintains ownership of all intellectual property in the Service and Licensed&nbsp;Data.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start={1}>
            <li>
                <p>Meaning of words&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>The following words have their defined meaning when used in these Terms:&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <ul>
            <li>
                <p>&apos;Access&apos; means to access, use, or otherwise benefit from the functionality of the&nbsp;Service. Access includes carrying out one or more of these actions, either personally or via an agent or employee.&nbsp;</p>
            </li>
            <li>
                <p>&apos;CSIRO&apos; means the Commonwealth Scientific and Industrial Research Organisation ABN 41 687 119 230.&nbsp;</p>
            </li>
            <li>
                <p>&lsquo;Client Data&rsquo; means data provided by you to access&nbsp;the&nbsp;Service.&nbsp;</p>
            </li>
            <li>
                <p>&lsquo;Licensed Data&rsquo;&nbsp;means&nbsp;data&nbsp;made available by CSIRO through this&nbsp;Service.&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </li>
            <li>
                <p>&lsquo;Service&rsquo;&nbsp;means the&nbsp;LOOC-B biodiversity&nbsp;platform delivering the&nbsp;Licensed Data.&nbsp;</p>
            </li>
        </ul>
    </div>
</div>
<div>
    <div>
        <ul>
            <li>
                <p>&apos;you&apos; means each person that accesses the&nbsp;Service&nbsp;and also&nbsp;includes your employer or principal when you have authority to act on their behalf:&nbsp;&apos;your&apos; has a corresponding meaning.&nbsp;</p>
            </li>
        </ul>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start={2}>
            <li>
                <p>Changes to Terms, Additional Product Terms or Privacy Statement&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>The Terms may be varied by CSIRO from time to time. CSIRO will use reasonable endeavours to&nbsp;alert you to&nbsp;substantive changes to the Terms. However, it is strongly advised that you&nbsp;check for updates of the Terms each time that you access the Service.&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <ol start={3}>
            <li>
                <p>Your&nbsp;Access&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>CSIRO grants you a non-exclusive and non-transferable right to&nbsp;use the Service&nbsp;in accordance with the standard functionality made available from time to time by CSIRO.&nbsp;&nbsp;&nbsp;</p>
    </div>
    <div>
        <p>You may only use the Service for internal research.&nbsp;</p>
    </div>
    <div>
        <p>Any other type of use requires prior written consent from CSIRO or any relevant rights owner.&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <p>Without limiting your obligations, unless permitted in writing by CSIRO, you must not:&nbsp;</p>
    </div>
    <div>
        <ol start={1}>
            <li>
                <p>attempt to obstruct, avoid, obscure or otherwise render ineffective any aspect of the&nbsp;Service or Licensed Data, including by removing or avoiding security features, attributions, or legal terms that are notified to users as part of&nbsp;Access;&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={2}>
            <li>
                <p>reverse engineer or otherwise seek to copy or reproduce any part of the functionality, design, or software incorporated into the&nbsp;Service or Licensed&nbsp;Data;&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={3}>
            <li>
                <p>make available the whole or part of the&nbsp;Service or Licensed Data&nbsp;for access by a third party (other than your employer or principal); or&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={4}>
            <li>
                <p>otherwise release or distribute log-in details for the&nbsp;Service&nbsp;and/or&nbsp;Licensed Data.&nbsp;</p>
            </li>
        </ol>
    </div>
</div>
<div>
    <div>
        <p>Where applicable, you are responsible for the acts and omissions of your employees and&nbsp;contractors&nbsp;and you are liable for any failure by your employees and contractors to observe these Terms.&nbsp;</p>
    </div>
    <div>
        <ol start={5}>
            <li>
                <p>Own risk&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>CSIRO makes no representations or warranties as to the&nbsp;accuracy, completeness or reliability&nbsp;of the Service or Licensed Data. Without limiting the forgoing, information on the&nbsp;Service and/or Licensed Data:&nbsp;</p>
    </div>
    <div>
        <ol start={1}>
            <li>
                <p>is not professional, scientific, medical, technical or expert&nbsp;advice;&nbsp;</p>
            </li>
        </ol>
    </div>
</div>
<div>
    <div>
        <ol start={2}>
            <li>
                <p>is subject to the usual uncertainties of advanced scientific and technical&nbsp;research;&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={3}>
            <li>
                <p>may not be accurate, suitable, current or&nbsp;complete;&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={4}>
            <li>
                <p>is subject to change without notice; and&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={5}>
            <li>
                <p>should never be relied on as the sole basis for doing or failing to do something.&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <ol start={6}>
            <li>
                <p>Data&nbsp;</p>
            </li>
        </ol>
    </div>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>a)Client Data&nbsp;</p>
</div>
<div>
    <p>CSIRO will only use Client Data for the sole purpose of providing, improving or expanding the Service.&nbsp; This includes any personal information (such as your name, employer and email) that you provide to CSIRO as part of accessing the Service.&nbsp;</p>
</div>
<div>
    <p>b) Licensed Data&nbsp;</p>
</div>
<div>
    <p>CSIRO&nbsp;maintains copyright&nbsp;in&nbsp;the Licensed Data, and&nbsp;CSIRO does not assign any of the Licensed Data to you. &nbsp;&nbsp;</p>
</div>
<div>
    <p>CSIRO grants you a non-exclusive license to&nbsp;use the Licensed Data for&nbsp;the sole purpose of&nbsp;internal research. &nbsp;&nbsp;</p>
</div>
<div>
    <p>You must not use the Licensed Data for any commercial purpose, including by providing consultancy services.&nbsp;</p>
</div>
<div>
    <p>You must not&nbsp;and must not&nbsp;permit anyone else to copy, store, adapt, modify, transmit or distribute the Licensed Data (but if you are an&nbsp;organisation&nbsp;you may allow others within your organisation to do so in accordance with these Terms).&nbsp;</p>
</div>
<div>
    <p>You agree to notify CSIRO promptly if you know or suspect any infringement or threatened infringement of the Licensed Data. You will provide CSIRO with all reasonable assistance&nbsp;in&nbsp;such proceedings.&nbsp;</p>
</div>
<div>
    <p>CSIRO&nbsp;may impose data volume limits to protect against misuse of the Licensed Data.&nbsp;</p>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>d)Data updates&nbsp;</p>
    </div>
    <div>
        <p>The Licenced Data may change or be updated from time to time, and therefore historical Licensed Data obtained from the Service may not be&nbsp;as accurate as Licensed Data more recently supplied.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start={7}>
            <li>
                <p>Intellectual Property&nbsp;</p>
            </li>
        </ol>
    </div>
</div>
<div>
    <div>
        <p>All intellectual property rights (including any copyright) and other rights in and to&nbsp;the Licensed Data and the Service are owned by CSIRO.&nbsp;</p>
    </div>
    <div>
        <p>You must not change any of the material or remove any part of any copyright or trademark notice in relation to this&nbsp;Service or Licensed Data.&nbsp;</p>
    </div>
    <div>
        <ol start={8}>
            <li>
                <p>Password and access to account&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>The security of your password to access the&nbsp;Service&nbsp;is solely your responsibility and you should not share it with anyone nor store it in an insecure place.&nbsp;</p>
    </div>
    <div>
        <p>You should not allow others to use your account to access the&nbsp;Service or Licensed Data&nbsp;(but if you are an&nbsp;organisation&nbsp;you may allow others within your organisation to do so in accordance with these terms).&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <ol start={9}>
            <li>
                <p>Enforcement&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>If CSIRO considers that you have failed to comply with these&nbsp;Terms, it may take disciplinary action against you. Such action includes withdrawing your access&nbsp;to the Service.&nbsp;</p>
    </div>
    <div>
        <p>In addition, CSIRO reserves all other legal rights that it may have.&nbsp;</p>
    </div>
    <div>
        <ol start={10}>
            <li>
                <p>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Audit&nbsp;and access&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>To enable CSIRO to verify compliance with the Terms,&nbsp;you agree that CSIRO or its representative may, acting reasonably, conduct audits of your compliance, including&nbsp;by&nbsp;visiting&nbsp;the&nbsp;workplaces where you&nbsp;use&nbsp;the Service&nbsp;or accessing the computers&nbsp;that you&nbsp;use for the Service.&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>CSIRO will use the information and material gathered in an audit to assess&nbsp;your compliance with the Terms.&nbsp;</p>
    </div>
    <div>
        <p>Without limiting CSIRO&rsquo;s remedies at law, where an audit identifies any non-compliance, CSIRO may direct you to take any action to rectify the non-compliance and you will reimburse CSIRO for its costs associated with such audit.&nbsp;</p>
    </div>
    <div>
        <ol start={11}>
            <li>
                <p> Indemnity&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>You release CSIRO from and agree to indemnify CSIRO against all actions, claims, losses, expenses, costs or damages of any kind (including special, incidental, punitive, exemplary or consequential) incurred or sustained by or on behalf of CSIRO arising directly or indirectly from your breach of the Terms.&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Your liability to indemnify CSIRO is reduced proportionately to the extent that any negligent act or omission of CSIRO contributed to the relevant loss or liability.&nbsp;</p>
    </div>
    <div>
        <ol start={12}>
            <li>
                <p> Termination&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>CSIRO may terminate this Service, your access to the Service or this agreement at any time for any purpose.&nbsp;</p>
    </div>
    <div>
        <ol start={13}>
            <li>
                <p>Governing law&nbsp;</p>
            </li>
        </ol>
    </div>
</div>
<div>
    <p>These Terms are governed by and construed in accordance with the laws of New South Wales, Australia and you agree to submit to the jurisdiction of the courts in that State.&nbsp;</p>
</div>
<div>
    <p>Notwithstanding the foregoing, CSIRO has the right to commence and prosecute any legal or equitable action or proceeding before any non-Australian court of competent jurisdiction to obtain injunctive or other relief&nbsp;in the event that, in the opinion CSIRO, such action is necessary or desirable.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>

        </Container>)
}

export default Terms