import React, {useState} from 'react'
import { Box, Button } from '@material-ui/core'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import authStore from '../stores/authStore';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems:'center',
            marginTop: 80,
          },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        copy: {
            float: 'right',
            fontSize: 12,
            margin: '-8px -8px 1px 1px',
            padding: '0 2px',
            backgroundColor: 'gray',
            '&:hover': {
                cursor: 'pointer'
            }
        }
    }),
);

const Profile = observer(() => {
    const classes = useStyles();
    const [copied, setCopied] = useState(false)

    if (!authStore.authorized) return <Redirect to='/login' />
    if(!authStore.user) return <div>
            <p>Fail to validate your credential, please logout and login again to refresh your credential.</p>
            <Button variant='outlined' onClick={() => authStore.logout()}>Logout</Button>
    </div>
    return (
        <div className={classes.root}>
            <h3>Profile</h3>
            <hr />
            <ul>
                <li>User Id: {authStore.user.username}</li>
                <li>Family Name: {authStore.user.family_name}</li>
                <li>Given Name: {authStore.user.given_name}</li>
                <li>Authorized Time: {new Date(authStore.user.auth_time*1000).toLocaleString()}</li>
                <li>Authorized Expire: {new Date(authStore.user.exp*1000).toLocaleString()}</li>
                <li>Authorized Valid: {authStore.authorized? 'Yes' : 'No'}</li>
                <li>User Group: 
                    <ul>
                    {
                        authStore.user.group&&authStore.user.group.map(g => <li>{g}</li>)
                    }
                    </ul>
                </li>
                {/* <li>Email: {authStore.email}</li> */}
                {/* <li>Login Name: {authStore.username} </li>
                <li>Disabled: {authStore.disabled} </li> */}
                <li>Token: 
                <div style={{wordWrap: 'break-word', width:'500px', color: '#333',padding: '10px', backgroundColor: '#F5F5F5', border: '1px solid #ccc', borderRadius: '4px'}}>
                    <div className={classes.copy} style={{ backgroundColor: `${copied ? 'green' : 'gray'}` }} onClick={() => { navigator.clipboard.writeText(authStore.authInfo.access_token); setCopied(true) }}>{copied ? 'copied' : 'copy'}</div>
                    {authStore.authInfo&& authStore.authInfo.access_token||'None'}
                {/* <pre style={{color: '#333',width: '100%', padding: '10px', display: 'inline-block', backgroundColor: '#F5F5F5', border: '1px solid #ccc', borderRadius: '4px'}}>
                <code>
                        {authStore.authInfo.access_token||'None'}
                </code>
                </pre> */}
            </div>
                </li>

            </ul>

                    
            <Button variant='outlined' onClick={() => authStore.logout()}>Logout</Button>
        </div>)
}
)

export default Profile