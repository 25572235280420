import React, { useCallback, MouseEvent } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Slide,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid
} from "@material-ui/core";
import EstimateContext from "./EstimateContext";
import estimateStore, { ErrorMessage } from "../stores/estimateStore";
import estimateContextStore from '../stores/estimateContextStore';
import useDraw, { DrawAction } from "./hooks/useDraw";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 1,
        // maxHeight: 'inherit',
        // overflow: "hidden",
        padding: '0 0.5rem 0 0.5rem',
        overflowY: "scroll", // added scroll
        width: '35%',
        [theme.breakpoints.down('md')]: {
            width: '40%',
            overflowY: "scroll", // added scroll
        },
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            overflowY: "scroll", // added scroll
        },
        height: `${100 - (64 * 100 / window.innerHeight)}vh`,
    },
    toolbar: theme.mixins.toolbar,
    dragger: {
        width: "5px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "#f4f7f9"
    },
    estimateButton: {
        width: '100%',
        color: 'white',
        backgroundColor: 'green',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    estimateButtonClear: {
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    }
}));

// Tooltop for reset button
const ResetTooltip = withStyles({
    tooltip: {
      fontSize: "1em",
      color: "white",
      backgroundColor: "red",
      placement: "top",
      padding: 8
    },
    arrow: {
        "&::before": {
          backgroundColor: "red",
        }
      }
  })(Tooltip);

const LeftDrawer = observer(() =>{
    const classes = useStyles();
    const { estimateDraw, handleDrawClick } = useDraw()

    const handleEstimate = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        estimateStore.requestEstimate()
    }, [estimateStore, estimateStore.requestEstimate])

    const handleClearSelected = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        estimateContextStore.resetAllRadioSelectValues()
        handleDrawClick(event, DrawAction.CLOSE)
    }, [estimateContextStore, estimateContextStore.resetAllRadioSelectValues])

    return (
        <Slide in={!estimateDraw} direction="right" mountOnEnter unmountOnExit>
            <Box className={classes.drawer}>
                <EstimateContext />
                <Divider />
                <Grid item xs={12}>
                    <div style={{color: "gray", fontSize: 14}}>How to: <br/>Input Estimate Context -&gt; Select interested area -&gt; Click following "ESTIMATE" button</div>
                    <br/>
                    <Box sx={{ display: 'flex' }}>
                        <ResetTooltip title="Warning! Clicking the RESET button will clear any drawn or loaded polygons" arrow>
                            <Button variant="contained" onClick={handleClearSelected} size="large" className={classes.estimateButtonClear}>
                                Reset
                            </Button>
                        </ResetTooltip>
                        <Button variant="contained" onClick={handleEstimate} size="large" className={classes.estimateButton}>
                            Estimate
                        </Button>
                        
                    </Box>
                    <Dialog
                        open={estimateStore.showContextAlert}
                        onClose={estimateStore.closeContextAlert}
                    >
                        {estimateStore.messages?.length > 0 && (
                            <>
                                <DialogTitle>You missed following context</DialogTitle>
                                <DialogContent>
                                    {estimateStore.messages.map((item: ErrorMessage) =>
                                            <DialogContentText key={item.id}>
                                                {item.msg}
                                            </DialogContentText>
                                        )
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={estimateStore.closeContextAlert}>Close</Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                </Grid>
            </Box>
        </Slide>
    );
})
export default LeftDrawer
