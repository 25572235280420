import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core'
import { Link } from "react-router-dom";
import { REACT_APP_COGNITO_CLIENT_ID } from '../config'
import AboutPicture from '../resources/Digiscape_CoBenefits_ConceptualFigure_2023-04-03.png'

const About = observer(()=><Container style={{ marginTop: 100 }}>
    <h1>About LOOC-B</h1>

    <p>
        LOOC-B provides access to biodiversity data and analyses for Australia and is developed by CSIRO. 
        The current tool focusses on planning and monitoring the biodiversity co-benefits of carbon 
        farming actions, though broader applications exist.  
    </p>

    <p>
        A technical description of the data and analyses underlying LOOC-B is available <a href="https://apidoc.looc-b.farm/overview.html">here</a>.  
        Below is a summary of the main data and analyses available via LOOC-B. Contact the LOOC-B 
        development team <a href="mailto:looc-b@csiro.au">here</a> for more information or to provide feedback. 
    </p>
    <p>
        As with all data derived from remotely sensed products, the biodiversity indicators provided in LOOC-B 
        will be updated, improved and added to over time, as the underlying datasets and analytical approaches 
        are refined. The data provided in LOOC-B may be less accurate in certain areas and may differ from 
        expectations or from on-ground measurements. 
    </p>

    <h2>Habitat-based biodiversity indicators </h2>
    <p>
        The biodiversity indicators available via LOOC-B apply a habitat-based approach to spatial biodiversity 
        assessment. Central to this approach is the estimation of habitat condition from remotely sensed data. 
        Habitat condition in an area of interest forms the basis for deriving indicators of habitat connectivity, 
        biodiversity persistence and habitat provision for nationally listed threatened species.
    </p>
    <p>
        <img height={600} style={{ padding: '10px'}} src={AboutPicture}></img>
        <p>
            <i>
                A simplified conceptual illustration of the approach used to generate the biodiversity indicators provided 
                by LOOC-B. Multiple data sources (grey boxes) are integrated in scientific models and analyses (blue boxes) 
                to produce spatially informed biodiversity indicators (green boxes). For LOOC-B, the indicator of habitat 
                condition (top) provides the basis for additional indicators, which are processed with additional data and 
                model integration. As better data becomes available, the reliability of the indicators is improved. 
            </i>
        </p>
    </p>

    <h2>Habitat condition</h2>
    <p>
        Habitat condition is the capacity of an area to provide the structures and functions necessary for the persistence 
        of all species naturally expected to occur in that area if it were in an intact (reference) state <sup><a href="#1">1</a></sup> 
        (being the most intact examples of native terrestrial ecosystems across contemporary Australia). Habitat condition is scaled between 
        0 and 1.0 in which a score of 1.0 indicates that the habitat is in an intact reference state (i.e., it has high levels 
        of ecological integrity) and a score of 0 means there is no capacity for naturally occurring species to persist. 
    </p>
    <p>
        The habitat condition data in the <code>monitoring</code> mode are an annual time-series of estimated habitat condition (2004-2020) 
        at 100 m resolution, developed by CSIRO. These data have been generated by combining two methods for estimating habitat condition; 
        (1) for areas that are expected to naturally have tree cover we apply a method using the time-series of 
        National Forest and Scattered Woody annual layers<sup><a href="#2">2</a></sup> (see <a href="https://apidoc.looc-b.farm/overview.html#habitat-condition"></a>), 
        and; (2) for areas that naturally have very sparse or no tree cover we apply a method using fractional cover derived from 
        Landsat<sup><a href="#3">3</a></sup> (see <a href="https://apidoc.looc-b.farm/overview.html#habitat-condition">here</a>). 
    </p>
    <p>
        To predict future habitat condition in the <code>planning</code> mode, under different proposed carbon farming actions, we apply simple 
        models of expected change, which vary with the starting habitat condition and the carbon farming action implemented (see <a href="https://apidoc.looc-b.farm/overview.html#planning">here</a>). 
    </p>
    <p>
        Data provided by LOOC-B estimates are a summary across all the gid cells within the specified area (polygon). Both the monitoring 
        and planning habitat condition data will be refined and updated over time, as our data, methods and knowledge improve. 
    </p>

    <h2>Biodiversity persistence </h2>
    <p>
        Biodiversity persistence is the number of species within the specified area that are expected to persist over the long term.  
    </p>
    <p>
        Biodiversity persistence in the area of interest is estimated based on two data inputs: (1) habitat condition values for every 
        grid cell, and (2) the biodiversity patterns that would be expected if all locations were in an intact reference state 
        <sup><a href="#4">4</a>,<a href="#5">5</a></sup>. 
        Biodiversity patterns are represented as maps derived from biodiversity models that utilize large amounts of on-ground biodiversity 
        data (e.g. more than 100k plant survey plots). Combining a map of habitat condition at a particular time with expected biodiversity 
        patterns, we can estimate how many of the species that originally occurred in our area of interest that we expect to persist into the 
        future, anywhere across their range (see <a href="https://apidoc.looc-b.farm/overview.html#biodiversity-persistence">here</a>). 
    </p>

    <h2>Habitat connectivity</h2>
    <p>
        Habitat connectivity is the connectedness of each location (grid cell) to natural habitat in the surrounding landscape<sup><a href="#4">6</a></sup>. Habitat 
        connectivity values range between 0 and 1.0, where a value of 1.0 indicates the grid cell is in an intact reference state and is 
        fully connected to a landscape where all other locations are also in an intact reference state. A habitat connectivity value of 0 
        indicates that the grid cell has a habitat condition of 0, and has no connectivity to any locations in the surrounding landscape 
        with any value as habitat. 
    </p>
    <p>
        To assess the connectivity of habitat, the key input are the habitat condition data. We use an approach that considers the easiest way 
        for species to move across the landscape given the spatial configuration of habitat condition values<sup><a href="#6">6</a>,<a href="#7">7</a></sup>. Connectivity is higher when 
        habitat is connected by continuous areas of habitat in good condition (see <a href="https://apidoc.looc-b.farm/overview.html#habitat-connectivity">here</a>). 
    </p>

    <h2>Threatened species habitat provision</h2>
    <p>
        The contribution of habitat in a specified area to supporting nationally listed threatened species is estimated by combining information on the spatial distribution 
        of threatened species, with data on habitat condition. The locations in which nationally listed threatened species may occur, given suitable habitat, were taken from 
        the Species of National Environmental Significance database <sup><a href="#8">8</a></sup>. These spatial distributions were refined using information from the Atlas of Living Australia <sup><a href="#9">9</a></sup>, the pre-European 
        distribution of vegetation communities <sup><a href="#10">10</a></sup> and data on elevation. 
    </p>
    <p>
        For each location, the provision of habitat for threatened species is estimated by multiplying the number of threatened species that may occur in a location by the 
        estimated habitat condition in that location, where this is 50 % or greater. For an area of any size, summing the value of each location provides an estimate of the 
        number of ‘threatened species hectares’ of habitat that is being provided across that area. Higher values indicate a greater contribution of the area in providing habitat 
        for threatened species (see <a href="https://apidoc.looc-b.farm/overview.html">here</a>).   
    </p>  
    <h2>Acknowledgements</h2>
    <p>
        The first version of LOOC-B was funded by CSIRO’s Digiscape Future Science Platform.
    </p>
    <h2>References</h2>
    <p>
        <ul style={{listStyleType:'number'}}>
            <li id="1">Williams KJ, Donohue RJ, Harwood TD, Lehmann EA, Lyon P, Dickson F, Ware C, Richards AE, Gallant J, Storey RJL, Pinner L, Ozolins M, Austin J, White M, McVicar TR, Ferrier S (2020).<cite> Habitat Condition Assessment System: developing HCAS version 2.0 (beta). A revised method for mapping habitat condition across Australia. </cite>Publication number EP21001. CSIRO, Canberra, Australia. <a href="https://doi.org/10.25919%2F85f4-1k65">https://doi.org/10.25919%2F85f4-1k65</a> </li>
            <li id="2">DISER (2021) <cite>National forest and sparse woody vegetation data (Version 5.0 - 2020 Release).</cite> Department of Industry, Science, Energy and Resources, Commonwealth of Australia, Canberra. <a href="https://data.gov.au/data/dataset/national-forest-and-sparse-woody-vegetation-data-version-5-2020-release">https://data.gov.au/data/dataset/national-forest-and-sparse-woody-vegetation-data-version-5-2020-release</a></li>
            <li id="3">Geoscience Australia (2021) <cite>Geoscience Australia Landsat Fractional Cover Percentiles Collection 3.</cite> Geoscience Australia. <a href="https://data.dea.ga.gov.au/?prefix=derivative/ga_ls_fc_pc_cyear_3/">https://data.dea.ga.gov.au/?prefix=derivative/ga_ls_fc_pc_cyear_3/</a></li>
            <li id="4">Ferrier, S., Powell, G. V. N., Richardson, K. S., Manion, G., Overton, J. M., Allnutt, T. F., . . . Van Rompaey, R. (2004).<cite> Mapping more of terrestrial biodiversity for global conservation assessment. </cite>Bioscience, 54(12), 1101-1109.</li>
            <li id="5">Allnutt, T. F., Ferrier, S., Manion, G., Powell, G. V. N., Ricketts, T. H., Fisher, B. L., . . . Rakotondrainibe, F. (2008). <cite>A method for quantifying biodiversity loss and its application to a 50-year record of deforestation across Madagascar. </cite>Conservation Letters, 1(4), 173-181. 10.1111/j.1755-263X.2008.00027.x </li>
            <li id="6">Ferrier, S., Harwood, T.D., Ware, C., Hoskins, A.J. (2020). <cite>A globally applicable indicator of the capacity of terrestrial ecosystems to retain biological diversity under climate change: The bioclimatic ecosystem resilience index.</cite>Ecological Indicators, 117, 106554. <a href="https://doi.org/10.1016/j.ecolind.2020.106554">https://doi.org/10.1016/j.ecolind.2020.106554</a> </li>
            <li id="7">Drielsma, M., Ferrier, S., &amp; Manion, G. (2007). <cite>A raster-based technique for analysing habitat configuration: 
                            The cost-benefit approach. </cite>Ecological Modelling, 202(3-4), 324-332. 10.1016/j.ecolmodel.2006.10.016</li>
            <li id="8">DAWE. (2022). <cite>Australia - Species of National Environmental Significance Distributions (public grids). </cite>Commonwealth of Australia (Department of Agriculture, Water and the Environment), Canberra. <a href="http://www.environment.gov.au/fed/catalog/search/resource/details.page?uuid=%7B337B05B6-254E-47AD-A701-C55D9A0435EA%7D">link</a></li>
            <li id="9">Atlas of Living Australia (2022). <cite>Atlas of Living Australia </cite> <a href="https://www.ala.org.au/"></a></li>
            <li id="10">NVIS (2020). <cite>National Vegetation Information System (NVIS) Version 6.0 - AUSTRALIA - Estimated Pre-1750 Vegetation. </cite>Commonwealth of Australia (Department of Agriculture, Water and the Environment), Canberra. <a href="http://environment.gov.au/fed/catalog/search/resource/details.page?uuid=%7BB26A2C5D-5964-4EE4-BA29-8DAA7C90ABA2%7D">link</a></li>
        </ul>
    </p>

</Container>)

export default About