import { observer } from "mobx-react-lite";
import { Button, Container } from '@material-ui/core'
import { Link, NavLink } from "react-router-dom";
import API_URL, {REACT_APP_COGNITO_CLIENT_ID} from '../config'
import IntroductionImage from '../resources/introduction.jpg'
import { useEffect } from "react";
import { requests } from "../stores/apiagent";
// @ts-ignore
import HowToVideo from "../resources/LOOC-B-BiodiversityShort 5-Instructional-Video004.mp4"
import { Alert } from "@material-ui/lab";

import { AccountCircle, PlaylistAddCheckOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core'

const Introduction = observer(()=>{
    useEffect(()=>{
        //warm up the lambda function
        requests.get(`${API_URL.ROOT_URL}${API_URL.WARM_UP_LAMBDA}`).then(()=>console.log('warmed up.'))
    }, [])
    return(
        <Container style={{ marginTop: 100 }}>
            <div>
                <h1>LOOC-B</h1>
                <h2>A Landscape Options and Opportunities Calculator - for Biodiversity</h2>
                {/* <Alert severity="warning">Tool for demonstration purposes only // Not intended for commercial use.</Alert> */}
                <img height={400} style={{float: 'right', padding: '10px'}} src={IntroductionImage}></img>
                <p>To use this tool, you must create an account and login using the login feature 
                        <IconButton to="/profile" component={Link} color="inherit">
                            <AccountCircle />
                        </IconButton>
                </p>
                {/* <p>Quantify the biodiversity co-benefits of land management actions such as carbon farming (at any scale) anywhere in Australia </p> */}

                <h2>What is LOOC B? </h2>
                <p>
                    The LOOC B tool offers a consistent standardised assessment of biodiversity indicators suitable for <b>monitoring</b> how 
                    biodiversity has changed over time and for <b>anticipating</b> the likely benefits of implementing different management 
                    strategies.  
                </p>
                <p>
                    The user interface (this website) is intended to be an easy way to experience the tool’s functionality: to obtain 
                    biodiversity information for a specified area in Australia.  
                </p>
                <p>
                    LOOC B allows you to quickly assess likely biodiversity change when: 
                </p>
                <ul>
                    <li><b>Planning </b>a carbon-farming action, or </li>
                    <li><b>Monitoring </b>carbon-farming action, such as tree planting </li>
                </ul>
                <p>
                    The estimates are calculated using an advanced machine learning approach that is continuously improved as new data 
                    becomes available. 
                </p>

                {/* <h2>Why?</h2>
                <p>
                LOOC-B offers a complementary approach to on-ground surveys for biodiversity assessment. On-ground surveys can provide valuable information, but they are costly and limited in coverage over space and time.  
                </p>

                <h2>How?</h2>
                <p>
                    LOOC-B combines two important information sources on biodiversity: 
                    <ul>
                        <li><b>Habitat condition</b> – estimated from satellite-based remote sensing.</li>
                        <li><b>Biodiversity patterns</b> – estimated by models using millions of on-ground species observations across Australia. </li>
                    </ul>
                    The estimates are calculated using an advanced machine learning approach that is continuously improved as new data becomes available.
                </p> */}
            </div>
            <br/>
            <br/>
            <div style={{minHeight: 600}}>
                <video height={400} controls style={{ float: 'left', paddingRight: '30px'}} src={HowToVideo}></video>
                <h2>Using LOOC-B</h2>
                <p>
                    The tool is currently in development, not yet ready for commercial use. 
                </p>
                <p>
                    To access LOOC-B via web interface, 1<sup>st</sup> <Link to='/login'>register</Link> for a personal account.
                    and accept the terms and conditions. 2<sup>nd</sup>, login-in on this landing page, or - if already registered, use the <Link to='/estimate'>Estimate</Link> page to start exploring.
                </p>
                <p>
                    For technical folk, you may wish to see the <a href="https://apidoc.looc-b.farm/">LOOC-B API</a>. 
                </p>
                <div>
                    <Button variant="contained" color='primary' component={Link} to='/estimate' size="large">
                        Go To Estimate
                    </Button>
                </div>

                <p>
                    For more information on this research project , see <a href="https://research.csiro.au/digiscape/digiscapes-projects/biodiversity-co-benefits-calculator/">https://research.csiro.au/digiscape/digiscapes-projects/biodiversity-co-benefits-calculator/</a>. 
                </p>
                <p>
                    For more information about the scientific approach, indicators, or inquire about a license, email the team at <a href="mailto:looc-b@csiro.au">looc-b@csiro.au</a> 
                </p>
                
            </div>

    </Container>)
    })

export default Introduction