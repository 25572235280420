export type LatLng = { lat: number; lng: number };
export type MapboxPolyCoords = [number, number]; // note Mapbox order is [lng, lat]
export type Polygon = Array<MapboxPolyCoords>;

export type ChartRawData = {
    name: string,
    uv: number,
    pv: number,
    amt: number
}

export type AuthType = {
    id_token: string,
    access_token: string,
    expires_in: number,
    token_type: string,
}

export type UserType = {
    sub: string,
    group: Array<string>,
    iss: string,
    username: string,
    given_name: string,
    family_name: string,
    auth_time: number,
    exp: number,
}

export type MEAN_PERCENT_CHANGE = {
    values: {
        latest_observation: number,
        maximum_forecast: number,
        expected_change: number
    },
    units: string
}

export type SUM_CHANGE = {
    values: {
        latest_observation: number,
        maximum_forecast: number,
        expected_change: number
    },
    units: string
}

export type GAUGE_DATA_TYPE = {
    start: number, 
    estimate: number, 
    potential: number,
    percent: number
}

export type MEAN_SPECIES_PERSISTENCE_CHANGES = {
    values: {
        starting_estimate: number,
        latest_estimate: number,
        expected_change: number,
        without_action: number,
        additional_species: number,
        progress: GAUGE_DATA_TYPE
    },
    units: string,
}

export type SUM_THREATENED_SPECIES_CHANGES = {
    values: {
        starting_estimate: number,
        latest_estimate: number,
        expected_change: number,
        progress: GAUGE_DATA_TYPE
    },
    units: string,
}


export type ANNUAL_AVERAGES = {
    mean: object,
    fifth_percentile: object,
    ninety_fifth_percentile: object,
    sum: object
}

export type PERSISTENCE_ANNUAL_AVERAGES = {
    biodiversity_persistence: ANNUAL_AVERAGES
    biodiversity_persistence_degraded: ANNUAL_AVERAGES
    biodiversity_persistence_pristine: ANNUAL_AVERAGES
    biodiversity_persistence_noaction: ANNUAL_AVERAGES
}

export type EstimateResultDatasetType = {
    name: string,
    summary: {
        mean_percent_changes: MEAN_PERCENT_CHANGE,
        sum_changes: SUM_THREATENED_SPECIES_CHANGES,
        mean_species_persistence_changes: MEAN_SPECIES_PERSISTENCE_CHANGES,
        potential_change_habitat_area: {
            values: {
                potential_change_ha: number
            },
            units: string
        },
        estimatedStartYear: string,
        estimatedEndYear: string,
        estimatedActionYear: string|undefined,
    },
    /*annual_averages: {
        mean: object,
        fifth_percentile: object,
        ninety_fifth_percentile: object
    },*/
    annual_averages: ANNUAL_AVERAGES //|PERSISTENCE_ANNUAL_AVERAGES
    figure: string,
    geotiff: {
        bounds:{
            left: number,
            bottom: number,
            right: number,
            top: number
        },
        images: object,
    },
    units: string,
    attribution: string,
    mode: string
}

export type EstimateResultType = {
    assessment_area: number,
    datasets: Array<EstimateResultDatasetType>,
}

export type EstimateResultCardType = {
    type: string,
    header1: string,
    value: string,
    header2: string,
    footer: string,
    image: string
}

export enum ModeType {
    planning = "planning",
    monitoring = "monitoring"
}

export enum DataLayerType {
    HabitatCondition = "habitat-condition",
    HabitatConnectivity = "habitat-connectivity",
    BiodiversityPersistence = "biodiversity-persistence",
    TreeHeight = 'tree-height',
    Precipitation = "precipitation",
    ThreatenedSpecies = 'threatened-species'
}

export enum PlanningActionType {
    EnvironmentPlanting = "environmental_planting",
    PlantationNative = "plantation_native",
    PlantationExotic = "plantation_exotic",
    NaturalRegeneration = "natural_regeneration"
}


export default Polygon