import React from "react";

export type AppBarRouteType = {
    to: string;
    label: string;
    value: string;

}

export const appBarRoutes: AppBarRouteType[] = [
    {
        to: 'introduction',
        label: 'Introduction',
        value: '/introduction',
    },    
    {
        to: 'estimate',
        label: 'Estimate',
        value: '/estimate',
    },    
    {
        to: 'about',
        label: 'About',
        value: '/about',
    },
    {
        to: 'login',
        label: '',
        value: '/login',
    },
    {
        to: 'profile',
        label: '',
        value: '/profile'
    }

]