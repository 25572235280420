import { DataLayerType, EstimateResultCardType, EstimateResultDatasetType, ModeType } from "../types"
import { isObjectEmpty } from "../util"

/*function roundNumber(num: float, dec: int) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }
*/

const roundOne = (n: number, d: number) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d);

const estimateResultToCardData = (estimate: EstimateResultDatasetType) => {
    let cardDataArray: Array<EstimateResultCardType> = []

    if(estimate.name === DataLayerType.BiodiversityPersistence){
        //console.log(estimate.summary.mean_species_persistence_changes)
        Object.entries(estimate.summary.mean_species_persistence_changes.values).map(entry=>{
            let cardData: EstimateResultCardType = {} as EstimateResultCardType
            switch(entry[0]){
                case 'starting_estimate':
                    cardData.type = 'observation'
                    cardData.header1 = `Start observation ${estimate.summary.estimatedStartYear}`
                    cardData.value = entry[1]+''
                    cardData.header2 = 'plant species'
                    cardData.footer = ''
                    break
                case 'latest_estimate':
                    cardData.type = 'observation'
                    cardData.header1 = `Latest observation - ${estimate.summary.estimatedEndYear}`
                    cardData.value = entry[1] + ''
                    cardData.header2 = 'plant species'
                    cardData.footer = ''
                    break
                case 'expected_change': 
                    cardData.type = 'expected'
                    cardData.header1 = `Change ${estimate.summary.estimatedActionYear||estimate.summary.estimatedStartYear} to ${estimate.summary.estimatedEndYear}`
                    cardData.value = '' + entry[1] 
                    cardData.header2 = 'change in number of species'
                    cardData.footer = ''
                    break
                case 'without_action': 
                    cardData.type = 'observation'
                    cardData.header1 = `Without the action - ${estimate.summary.estimatedEndYear}`
                    cardData.value = '' + entry[1] 
                    cardData.header2 = 'plant species'
                    cardData.footer = ''
                    break
            }
            if(!isObjectEmpty(cardData)){
                cardDataArray.push(cardData)
            }
        })
    }else if(estimate.name === DataLayerType.HabitatCondition){
        Object.entries(estimate.summary.mean_percent_changes.values).map(entry=>{
            let cardData: EstimateResultCardType = {} as EstimateResultCardType
            switch(entry[0]){
                case 'starting_estimate':
                    cardData.type = 'observation'
                    cardData.header1 = `Start observation ${estimate.summary.estimatedStartYear}`
                    cardData.value = entry[1] + '%'
                    cardData.header2 = 'average habitat condition'
                    cardData.footer = 'Habitat condition for biodiversity '
                    break
                case 'latest_estimate':
                    cardData.type = 'forecast'
                    cardData.header1 = `Latest ${estimate.mode===ModeType.planning? 'forecast' : 'observation'} ${estimate.summary.estimatedEndYear}`
                    cardData.value = entry[1] + '%'
                    cardData.header2 = 'average habitat condition'
                    cardData.footer = 'Habitat condition for biodiversity '
                    break
                case 'expected_change': 
                    cardData.type = 'expected'
                    cardData.header1 = `Expected change to ${estimate.summary.estimatedEndYear} since ${estimate.summary.estimatedActionYear||estimate.summary.estimatedStartYear}`
                    cardData.value = '' + entry[1] + '%'
                    cardData.header2 = 'change habitat condition'
                    cardData.footer = 'Habitat condition for biodiversity'
                break
            }
            if(!isObjectEmpty(cardData)){
                cardDataArray.push(cardData)
            }
        })
    } else if (estimate.name === DataLayerType.HabitatConnectivity){
        Object.entries(estimate.summary.mean_percent_changes.values).map(entry=>{
            let cardData: EstimateResultCardType = {} as EstimateResultCardType
            switch(entry[0]){
                case 'starting_estimate':
                    cardData.type = 'observation'
                    cardData.header1 = `Start observation ${estimate.summary.estimatedStartYear}`
                    cardData.value = entry[1] + '%'
                    cardData.header2 = 'average habitat connectivity'
                    cardData.footer = 'Habitat connectivity for biodiversity '
                    break
                case 'latest_estimate':
                    cardData.type = 'forecast'
                    cardData.header1 = `Latest ${estimate.mode===ModeType.planning? 'forecast' : 'observation'} ${estimate.summary.estimatedEndYear}`
                    cardData.value = entry[1] + '%'
                    cardData.header2 = 'average connectivity condition'
                    cardData.footer = 'Habitat connectivity for biodiversity '
                    break
                case 'expected_change': 
                    cardData.type = 'expected'
                    cardData.header1 = `Expected change to ${estimate.summary.estimatedEndYear} since ${estimate.summary.estimatedActionYear||estimate.summary.estimatedStartYear}`
                    cardData.value = '' + entry[1] + '%'
                    cardData.header2 = 'change connectivity condition'
                    cardData.footer = 'Habitat connectivity for biodiversity'
                break
            }
            if (!isObjectEmpty(cardData)) {
                cardDataArray.push(cardData)
            }
        })
    } else if (estimate.name === DataLayerType.ThreatenedSpecies) {
        Object.entries(estimate.summary.sum_changes.values).map(entry=>{
            let cardData: EstimateResultCardType = {} as EstimateResultCardType

            switch(entry[0]) {
                case 'starting_estimate':
                    cardData.type = 'observation'
                    cardData.header1 = `Start observation ${estimate.summary.estimatedStartYear}`
                    cardData.value = entry[1] + ''
                    cardData.header2 = 'species hectares'
                    cardData.footer = 'Habitat for threatened species'
                    break
                case 'latest_estimate':
                    cardData.type = 'forecast'
                    cardData.header1 = `Latest ${estimate.mode===ModeType.planning? 'forecast' : 'observation'} ${estimate.summary.estimatedEndYear}`
                    cardData.value = entry[1] + ''
                    cardData.header2 = 'species hectares'
                    cardData.footer = 'Habitat for threatened species'
                    break
                case 'expected_change': 
                    cardData.type = 'expected'
                    cardData.header1 = `Expected change to ${estimate.summary.estimatedEndYear} since ${estimate.summary.estimatedActionYear||estimate.summary.estimatedStartYear}`
                    cardData.value = '' + entry[1] + ''
                    cardData.header2 = 'change in species hectares'
                    cardData.footer = 'Habitat for threatened species'
                break
            }
            if (!isObjectEmpty(cardData)) {
                cardDataArray.push(cardData)
            }
        })
    } else if (estimate.name === DataLayerType.TreeHeight) {

        // NOTE:: recycling habitat-condition terms in the API response here...
        // These will need to be updated in the API, and then again here.
        Object.entries(estimate.summary.mean_percent_changes.values).map(entry => {
            let cardData: EstimateResultCardType = {} as EstimateResultCardType

            switch(entry[0]) {
                case 'starting_estimate':
                    cardData.type = 'observation'
                    cardData.header1 = `Start observation ${estimate.summary.estimatedStartYear}`
                    cardData.value = entry[1] + 'm'
                    cardData.header2 = 'average tree hight'
                    cardData.footer = 'Tree height'
                    break
                case 'latest_estimate': 
                    cardData.type = 'forecast'
                    cardData.header1 = `Latest ${estimate.mode===ModeType.planning? 'forecast' : 'observation'} ${estimate.summary.estimatedEndYear}`
                    cardData.value = entry[1] + 'm'
                    cardData.header2 = 'average tree height'
                    cardData.footer = 'Tree height'
                    break
                case 'expected_change': 
                    cardData.type = 'expected'
                    cardData.header1 = `Expected change to ${estimate.summary.estimatedEndYear} since ${estimate.summary.estimatedActionYear||estimate.summary.estimatedStartYear}`
                    cardData.value = '' + entry[1] + 'm'
                    cardData.header2 = 'average tree hight'
                    cardData.footer = 'Tree height'
                break
            }
            if (!isObjectEmpty(cardData)) {
                cardDataArray.push(cardData)
            }
        })
    }

    return cardDataArray
}
export default estimateResultToCardData