import React from 'react'
import { Button, Grid, LinearProgress, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import MapWithImageLayer from "./MapWithImageLayer";

import estimateStore from "../stores/estimateStore";
import estimateContextStore from '../stores/estimateContextStore';
import estimateResultToCardData from './EstimateResultToCardData';
import GaugeChart from 'react-gauge-chart'

import { Alert } from "@material-ui/lab";
import { DataLayerType, EstimateResultDatasetType } from '../types';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        card:{
            borderRadius: 5,
            alignItems: 'center',
            margin: theme.spacing(1),
            border: '1px solid gray',
            marginTop: theme.spacing(3),
            boxShadow: '0 2px 4px rgb(0 0 0 / 30%)', 
        },
        padding: {
            padding: 10
        },
    }),
);

const EstimateResult = () => {
    const classes = useStyles()

    const dataset = estimateStore.planningEstimateResult.datasets
    console.info("🚀 ~ file: EstimateResult.tsx ~ line 46 ~ EstimateResult ~ dataset", dataset)

    // NOTE:: An error with no response was discovered when tracing around a small island.
    // most likely when too much water area is included in the area around the island.
    // The below commented error message will display in the right draw if the dataset is undefined.
    // uncomment if you wish for that message to be displayed in the situation where the dataset is undefined.
    // unfortunately there is no error message returned so we cant say why the error is actually happening.

    if (!dataset && !estimateStore.loading) {
        return (
            <Grid className={classes.root}>
                An error has occurred. Please try reducing the selected area of interest in size, and/or the time over which the assessment is run. If this error happens frequently please contact us.
            </Grid>
        )
    }

    if (!estimateStore.loading) {
       return(
            <Grid className={classes.root}>
                {estimateStore.planningEstimateResult.datasets
                    && estimateStore.planningEstimateResult.datasets.map(
                        (estimate: EstimateResultDatasetType, index) => {

                    return (
                        <Grid container spacing={1} key={index}>          
                            {estimate.name === DataLayerType.HabitatCondition && (
                                <>
                                    <Grid item xs={12} style={{ margin: `${1}rem ${0} ${1}rem ${0}` }}>
                                        <Typography variant='h5' noWrap>
                                            Area selected {Math.round(estimateStore.planningEstimateResult.assessment_area).toLocaleString()} ha.
                                        </Typography>
                                        <Typography variant='h6'>
                                            The following results for <b style={{ textDecoration: 'underline' }}>habitat condition</b> within the area selected are returned:
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {estimate.name === DataLayerType.BiodiversityPersistence && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" align="center">
                                            The number of plant species expected to persist in the area selected of {Math.round(estimateStore.planningEstimateResult.assessment_area).toLocaleString()} ha:
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {estimate.name === DataLayerType.TreeHeight && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            The average tree height across the area selected of {Math.round(estimateStore.planningEstimateResult.assessment_area).toLocaleString()} ha:
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            <Grid container item spacing={1} lg={12}>
                                {estimateResultToCardData(estimate)?.length > 0
                                    && estimateResultToCardData(estimate).map((cardData, index) => 
                                    <Grid item xs lg={estimateResultToCardData(estimate)?.length === 4 ? 6 : true} key={index}>
                                        <MapWithImageLayer cardData={cardData} />
                                    </Grid>
                                )}
                            </Grid>
                            <br/>
                            <br/>
                            {(estimate.name === DataLayerType.HabitatCondition || estimate.name === DataLayerType.HabitatConnectivity) && (
                                <Grid container item lg={12} className={classes.card} style={{ marginTop: '5px', margin: '5px 8.5px 0 0' }}>
                                   <Grid item xs={9}>
                                       <Typography variant='h6' align='left'>
                                            Estimated change in equivalent habitat area {estimate.summary.estimatedActionYear} to {estimate.summary.estimatedEndYear}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant='h5'
                                            align='right'
                                            style={{color: 'green'}}
                                        >
                                            {estimate.summary.potential_change_habitat_area.values.potential_change_ha >= 0 ? '+' + estimate.summary.potential_change_habitat_area.values.potential_change_ha.toLocaleString(): estimate.summary.potential_change_habitat_area.values.potential_change_ha.toLocaleString()} {estimate.summary.potential_change_habitat_area.units}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            {estimate.name === DataLayerType.TreeHeight && (
                                <Grid container item lg={12} className={classes.card} style={{ marginTop: '5px', margin: '5px 8.5px 0 0' }}>
                                    <Grid item xs={9}>
                                        <Typography variant='h6' align='left'>
                                            Estimated change in area of trees taller than &gt; 3m from {estimate.summary.estimatedActionYear?estimate.summary.estimatedActionYear:estimate.summary.estimatedStartYear} to {estimate.summary.estimatedEndYear}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant='h5'
                                            align='right'
                                            style={{color: 'green'}}
                                        >
                                            {estimate.summary.potential_change_habitat_area.values.potential_change_ha >= 0 ? '+' + estimate.summary.potential_change_habitat_area.values.potential_change_ha.toLocaleString(): estimate.summary.potential_change_habitat_area.values.potential_change_ha.toLocaleString()} {estimate.summary.potential_change_habitat_area.units}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            {estimate.name === DataLayerType.ThreatenedSpecies && (
                                <Grid container item lg={12} className={classes.card} style={{ marginTop: '5px', margin: '5px 8.5px 0 0' }}>
                                    <Grid item xs={8}>
                                        <Typography variant='h6' align='left'>
                                            Estimated change in species hectares from {estimate.summary.estimatedActionYear
                                                ? estimate.summary.estimatedActionYear
                                                : estimate.summary.estimatedStartYear
                                            } to {estimate.summary.estimatedEndYear}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant='h5'
                                            align='right'
                                            style={{color: 'green'}}
                                        >
                                            {estimate.summary.sum_changes.values.expected_change >= 0 ? '+' + estimate.summary.sum_changes.values.expected_change.toLocaleString(): estimate.summary.sum_changes.values.expected_change.toLocaleString()}
                                        </Typography>
                                        <Typography variant='h6' align='right' style={{color: 'green'}}>
                                            {estimate.summary.sum_changes.units}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            {estimate.name === DataLayerType.BiodiversityPersistence && (
                                <>
                                    <Grid container item lg={12} className={classes.card} style={{ marginTop: '5px', margin: '5px 8.5px 0 0', padding: '0.5rem' }}>
                                        <Grid item lg={10}>
                                            <Typography variant='h6' align='left'>
                                            Number of additional plant species persisting {estimate.summary.estimatedActionYear||estimate.summary.estimatedStartYear} to {estimate.summary.estimatedEndYear}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography
                                                variant='h5'
                                                align='right'
                                                style={{color: 'green'}}
                                            >
                                                {estimate.summary.mean_species_persistence_changes.values.additional_species}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className={classes.card} lg={12} style={{ marginTop: '5px', margin: '5px 8.5px 0 0', padding: '0.5rem' }}>
                                        <Grid item xs={5}>
                                            <Typography variant='h6' align='left'>
                                                Progress toward full potential gain in number of plant species persisting
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <GaugeChart  
                                                nrOfLevels={7} 
                                                textColor="gray"
                                                colors={["#c3d5bb", "#afc8a4", "#9bba8e", "#87ac77", "#739f60", "#5f914a", "#4b8333"]} 
                                                percent={estimate.summary.mean_species_persistence_changes.values.progress.percent/100} 
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant='h6' align='left' style={{color: 'green'}}>
                                                Start: {estimate.summary.mean_species_persistence_changes.values.progress.start}
                                            </Typography>
                                            <Typography variant='h6' align='left' style={{color: 'green'}}>
                                                Potential: {estimate.summary.mean_species_persistence_changes.values.progress.potential}
                                            </Typography>
                                            <Typography variant='h6' align='left' style={{color: 'green'}}>
                                                Estimate: {estimate.summary.mean_species_persistence_changes.values.progress.estimate}
                                            </Typography>
                                            <Typography variant='h6' align='left' style={{color: 'green'}}>
                                                Percent: {estimate.summary.mean_species_persistence_changes.values.progress.percent} %
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {estimate.name === DataLayerType.ThreatenedSpecies && (
                                <Grid container item className={classes.card} lg={12} style={{ marginTop: '5px', margin: '5px 8.5px 0 0' }}>
                                    <Grid item xs={5}>
                                        <Typography variant='h6' align='left'>
                                            Progress toward full potential threatened species habitat provision
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <GaugeChart  
                                            nrOfLevels={7}
                                            textColor="gray"
                                            percent={estimate.summary.sum_changes.values.progress.percent / 100} 
                                            colors={["#c3d5bb", "#afc8a4", "#9bba8e", "#87ac77", "#739f60", "#5f914a", "#4b8333"]} 
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h6' align='left' style={{color: 'green'}}>
                                            Start: {estimate.summary.sum_changes.values.progress.start}
                                        </Typography>
                                        <Typography variant='h6' align='left' style={{color: 'green'}}>
                                            Potential: {estimate.summary.sum_changes.values.progress.potential}
                                        </Typography>
                                        <Typography variant='h6' align='left' style={{color: 'green'}}>
                                            Estimate: {estimate.summary.sum_changes.values.progress.estimate}
                                        </Typography>
                                        <Typography variant='h6' align='left' style={{color: 'green'}}>
                                            Percent: {estimate.summary.sum_changes.values.progress.percent} %
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                style={{  margin: `${1}rem ${0} ${1}rem ${0}` }}
                            >
                                <iframe
                                    title="chart"
                                    srcDoc={estimate.figure}
                                    style={{ border: 'none', width:'100%', height: '840px' }}
                                ></iframe>
                                {/* <div className="content" dangerouslySetInnerHTML={{__html: estimate.figure}}></div> */}

                                {/* <Charts data={estimateStore.chartData} /> */}
                                {/* <img width='100%' src={`data:image/gif;base64,${estimate.figure}`} /> */}
                                {/* <img src={`data:image/gif;base64,${estimate.geotiff}`} /> */}
                            </Grid>
                            {estimate.name === DataLayerType.BiodiversityPersistence
                                && estimate.summary.mean_species_persistence_changes.values.expected_change < 0.5 && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        The above chart displays the contribution of the area of interest to the number of additional plant species expected to persist.
                                        The summary statistics in the cards below may show a decline in overall biodiversity persistence, despite an increase above, because of changes in regional habitat outside the area of interest.
                                    </Alert>
                                </Grid>
                            )}
                            {estimate.name === DataLayerType.ThreatenedSpecies
                                && estimate.summary.sum_changes.values.starting_estimate == 0.0 && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        The above chart displays the amount of habitat for threatened species provided by the area of interest, expressed in units of 'species hectares.' 
                                        Some regions will provide no habitat for threatened species, and the above chart will be empty.
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant='h6'>Estimate Context:</Typography>
                                <ul
                                    style={{
                                        margin: `${0}rem ${0}rem ${0}rem ${0}rem`,
                                        padding: `${0}rem ${0}rem ${0}rem ${1.3}rem`  
                                    }}
                                >
                                    <li>
                                        Estimate Mode: <b>{!estimateContextStore.mode ? 'N/A' : estimateContextStore.mode}</b>
                                    </li>
                                    <li>
                                        Data Layer: <b>{estimate.name}</b>
                                    </li>
                                    <li>
                                        Event or Action: <b>{!estimateContextStore.planningActionType ? 'N/A' : estimateContextStore.planningActionType}</b>
                                    </li>
                                    <li>
                                        Selected Size: <b>{Math.round(estimateStore.planningEstimateResult.assessment_area).toLocaleString()} ha</b>
                                    </li>
                                </ul>
                            </Grid>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </Grid>
                    )}
                )}
            </Grid>
        )
    } else return (
        <div style={{ minHeight:'30vh', marginTop: '150px' }}>
            <LinearProgress />
        </div>
    )
}

export default observer(EstimateResult)