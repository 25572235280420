import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import { EstimateResultCardType /*, EstimateResultType */} from '../types';
// import Mboxmap from "./Map/MapboxMap";
// import mapboxStore from '../stores/mapboxStore'

// const style = "mapbox://styles/mapbox/satellite-streets-v10?optimize=true"
// const mapStyle = {
//     height: "500px",
//     width: "100%",
//     maxWidth: "1140px"
// }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        card:{
            width: '100%',
            height: '100%',
            borderRadius: 5,
            alignItems: 'center',
            border: '1px solid gray',
            boxShadow: '0 2px 4px rgb(0 0 0 / 30%)'
        },
        padding: {
            padding: 10
        },
        greenText: {
            color: 'green'
        }
    }),
);


const MapWithImageLayer: React.FC<{cardData: EstimateResultCardType}> = observer(({cardData}) => {
    // const svgRef = React.useRef(null)
    const classes = useStyles()

    return (
        <Grid container className={classes.card}>
            <Grid item xs={12} className={classes.padding}>
                <Typography align="center" variant="h6">
                    <b>
                        {cardData.header1}
                    </b>
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.padding}>
                <Grid item>
                    <Typography
                        align='center'
                        variant="h4"
                        className={cardData.type === 'expected'
                            ? classes.greenText
                            : ''
                        }
                    >
                        {cardData.value}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography align='center' variant="subtitle1">
                        {cardData.header2}
                    </Typography>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <Mboxmap
                    center={mapboxStore.center}
                    zoom={mapboxStore.zoom}
                    fitBounds={true}
                    fitBoundsCallback={mapboxStore.fitBounds}
                    coords={mapboxStore.polygons[0]||[]}
                    style={style}
                    mapStyle={mapStyle}
                    showInteractive={false}
                    helperMessage={mapboxStore.helperMessage}
                    selectedArea={mapboxStore.getArea()}
                    initPolygon={mapboxStore.polygons[0]|| [[ 145.099033, -16.2621472],[ 146.297644, -16.2621472],  [146.297644, -17.4284139], [ 145.099033, -17.4284139] ]}
                    image={cardData.image}
                />
            </Grid> */}

            <Grid item xs={12}>
                <Typography align='center' variant="h6">
                    {cardData.footer}
                </Typography>
            </Grid>
        </Grid>)
    }
)

export default MapWithImageLayer