import axios from "axios";
import { AuthType } from '../types'
import API_URL from '../config'

//@ts-ignore
export const apiagent = (options) => {
    const tokenObj = localStorage.getItem('token')
    const token = JSON.parse(tokenObj||"{}") as AuthType
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    const request = axios.create({
        baseURL: API_URL.ROOT_URL
    });

    // Add a request interceptor
    request.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            return Promise.reject(requestError);
        }
    );

    // Add a response interceptor
    request.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status >= 500) {
                console.log('Server Error, please typing Ctrl+Shift+R on keyboard to refersh. If the error is the same, please contact info@instasoft.com.au')
                // message.error('Server Error, please typing Ctrl+Shift+R on keyboard to refersh. If the error is the same, please contact info@instasoft.com.au', 10);
            }
            return Promise.reject(error);
        }
    );

    //@ts-ignore
    const onSuccess = function (response) {
        console.debug("Request Successful!", response);
        return response.data;
    };

    //@ts-ignore
    const onError = function (error) {
        console.error("Request Failed:", error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error("Status:", error.response.status);
            console.error("Data:", error.response.data);
            console.error("Headers:", error.response.headers);
        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error("Error Message:", error.message);
        }
        console.log('Network Error, please make sure your network is connected properly, and refresh ...')
        // message.error('Network Error, please make sure your network is connected properly, and refresh ...', 10);

        return Promise.reject(error.response || error.message);
    };

    return request(options)
        .then(onSuccess)
        .catch(onError);
};

/**
 * Base HTTP Client
 */
export const requests = {
    get(url: string) {
        return apiagent({
            url: url,
            method: "get"
        });
    },

    delete(url: string) {
        return apiagent({
            url: url,
            method: "delete"
        });
    },

    post(url: string, data = {}, config = {}) {

        return apiagent({
            url: url,
            method: "post",
            data: data,
            config: config
        });
    },

    put(url: string, data = {}) {
        return apiagent({
            url: url,
            method: "put",
            data: data
        });
    },

    patch(url: string, data = {}) {
        return apiagent({
            url: url,
            method: "patch",
            data: data
        });
    }
};

export const Auth = {
    currentUser: () => requests.get('/current_user'),
    logout: () => requests.get('/logout')
}